/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import "../settingComponents.scss";
import styles from "assets/jss/material-dashboard-react/views/genericStyles";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import ApiUrls from "utils/ApiUrls";
import { PATCH, GET } from "utils/Functions";
import { connect } from "react-redux";
import { message } from "antd";
import ProfileImg from "./ProfileImg";
const useStyles = makeStyles(styles);
function ProfileForm(props) {
  const classes = useStyles();
  const [profileInfo, setProfileInfo] = useState({});
  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    getUserInfo();
  }, []);
  const getUserInfo = async () => {
    let res = await GET(ApiUrls.GET_PROFILE_INFO + props.userID);
    if (res.status == "200") {
      setUserInfo(res.profile_info);
    } else {
      message.error(res.message);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let resp = await PATCH(ApiUrls.UPDATE_PROFILE + props.userID, profileInfo);
    if (resp.status == "200") {
      message.success(resp.message);
    } else {
      message.error(resp.message);
    }
  };
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row className="settingComponents--form xs:flex-col-reverse sm:flex-col-reverse">
          <Col lg={6} className="mt-4">
            <Form.Group as={Col}>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={userInfo.first_name}
                onChange={(e) => {
                  setProfileInfo({
                    ...profileInfo,
                    first_name: e.target.value,
                  });
                }}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={userInfo.last_name}
                onChange={(e) => {
                  setProfileInfo({
                    ...profileInfo,
                    last_name: e.target.value,
                  });
                }}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Title</Form.Label>
              <Form.Control defaultValue={userInfo.title} type="text" />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Company Info</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Address 1</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Maecenas odio."
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Address 1</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Maecenas odio."
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <div className="position-relative h-32">
              <ProfileImg
                className="position-absolute right-2"
                classes="avatar-uploader"
                userID={props.userID}
                defaultImg={userInfo.avatar}
                userPicture
              />
            </div>
            <Form.Group as={Col}>
              <Form.Label>Phone</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Tax ID Number</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Country</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>State</Form.Label>
              <Form.Control type="text" />
            </Form.Group>

            <Row>
              <Col sm={12} md={6}>
                <Form.Group className="" as={Col}>
                  <Form.Label>Currency</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group className="" as={Col}>
                  <Form.Label>Time Zone</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Button
          variant="contained"
          type="submit"
          className="mb-3 mr-3 float-right "
          classes={{
            root: classes.themeBlue,
            label: classes.whiteLabelColor,
          }}
        >
          Save Changes
        </Button>
      </Form>
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    userID: state.Login.user_info._id,
  };
};

export default connect(mapStatetoProps)(ProfileForm);
