/*eslint-disable*/
import React, { Component } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "./editor.scss";

import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Button } from "antd";
import { Mentions } from "antd";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const { Option } = Mentions;

const toolbar = {
  options: ["inline", "list", "history"],
  inline: {
    options: ["bold", "italic", "underline", "monospace"],
    bold: { icon: require("assets/img/bold.svg"), className: undefined },
    italic: { icon: require("assets/img/italic.svg"), className: undefined },
  },
  list: {
    options: ["unordered", "ordered"],
  },
};
export class ReactDraftEditor extends Component {
  constructor(props) {
    super(props);
    const html = "<p></p>";
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    // this.props.returnHtml(
    //   draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // );
  };
  render() {
    const { editorState } = this.state;
    return (
      <>
        <div className="customEditor">
          {/* <Editor
          toolbar={toolbar}
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          toolbarClassName="toolbar-class"
          onEditorStateChange={this.onEditorStateChange}
        /> */}
          <Mentions
            style={{ width: "100%", height: "150px" }}
            bordered={false}
            defaultValue="@afc163"
          >
            <Option value="afc163">afc163</Option>
            <Option value="zombieJ">zombieJ</Option>
            <Option value="yesmeck">yesmeck</Option>
          </Mentions>
          <div className="editorAttachments">
            <Upload listType="picture" className="w-100">
              <div className="d-flex justify-content-between">
                <div className="content">
                  <span className="title">Attachments</span>
                  <span className="detail">
                    (you can click to select attachments)
                  </span>
                </div>
                <Button className="rounded-pill">Upload File</Button>
              </div>
            </Upload>
          </div>
        </div>
        <div className="mt-3 ">
          <Button className="rounded-pill mr-2">Add Comment</Button>
          <Button className="rounded-pill">Cancel</Button>
        </div>
      </>
    );
  }
}
