/*eslint-disable*/
import React, { useState, useEffect } from "react";
import {
  Drawer,
  Form,
  Input,
  DatePicker,
  Select,
  List,
  Checkbox,
  Comment,
  message,
  Avatar as AntAvatar,
  Tooltip as AntTooltip,
  Radio,
  Popover,
  Empty,
  Mentions,
  Upload,
} from "antd";
import { Row, Col, Form as RBForm } from "react-bootstrap";
import {
  Divider,
  Avatar,
  Button,
  ClickAwayListener,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { BiArchiveIn, BiArchiveOut } from "react-icons/bi";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import ApiUrls from "utils/ApiUrls";
import { POST, GET, PATCH, AXIOS_POST } from "utils/Functions";
import "./viewTaskDetails.scss";
import ColorPicker from "./ColorPicker";
import UploadFile from "components/UploadFile/UploadFile";
import AnimatedBtn from "components/CustomButtons/AnimatedBtn";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { LinkOutlined, CopyOutlined } from "@ant-design/icons";
import { baseeURL } from "utils/Config";
import InlineToolbarTextEditor from "components/RichTextEditor/InlineToolbarTextEditor";
import { motion, AnimatePresence } from "framer-motion";
import TimerButton from "components/TrackTime/TimerButton";
import axios from "axios";

const { Option } = Select;

const newList = {
  hidden: {
    opacity: 0,

    y: -2,
    transition: {
      type: "spring",
      when: "afterChildren",
      staggerChildren: 0.3,
    },
  },
  visible: {
    opacity: 1,

    y: 0,
    transition: {
      type: "spring",
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
};

const key = "updatable";
const layout = {
  // labelCol: {
  //   xs: { span: 24 },
  //   sm: { span: 4 },
  //   md: { span: 7 },
  //   xl: { span: 6 },
  //   xxl: { span: 3 },
  // },
  // wrapperCol: {
  //   xs: { span: 24 },
  //   sm: { span: 20 },
  //   md: { span: 18 },
  //   xl: { span: 18 },
  //   xxl: { span: 21 },
  // },
};
const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
const CommentList = ({ comments }) => {
  // console.log(comments, "comments====>");
  return (
    <List
      dataSource={comments}
      itemLayout="horizontal"
      renderItem={(item) => (
        <>
          <Comment
            author={item.sender?.username}
            avatar={
              <Avatar
                alt={item.sender?.username}
                src={baseeURL + item.sender?.avatar}
              />
            }
            content={item.message}
            datetime={moment(item.sending_time).format("L")}
          />
          <div className="ml-5">
            <Upload
              fileList={item.attachments}
              showUploadList={{
                showRemoveIcon: false,
              }}
              onPreview={async (file) => {
                window.open(baseeURL + file.url, "_blank");
              }}
            />
          </div>
        </>
      )}
    />
  );
};
const ListItem = ({ item, users, refresh, ...props }) => {
  const [openSubtaskAssignee, setOpenSubtaskAssignee] = useState(true);
  //

  return (
    <List.Item className="subtask">
      <List.Item.Meta />
      <div className="subtask--list w-100 d-flex flex-row align-items-center justify-content-between">
        <Checkbox
          checked={item.status == "complete" ? true : false}
          onChange={async (e) => {
            if (
              props.userType === "owner" ||
              props.userType === "collaborator"
            ) {
              message.loading({ content: "Action in progress..", key });

              let status = e.target.checked ? "complete" : "active";
              let resp = await PATCH(ApiUrls.EDIT_SUBTASK + item._id, {
                status: status,
              });

              if (resp.status == "200") {
                refresh();
                message.success({
                  content: resp.message,
                  key,
                  duration: 2.5,
                });
              } else message.error(resp.message);
            }
          }}
        />
        <Input
          defaultValue={item.title}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              let resp = await PATCH(ApiUrls.EDIT_SUBTASK + item._id, {
                title: e.target.value,
              });

              if (resp.status == "200") refresh();
            }
          }}
          style={{ width: "80%" }}
        />
        <ClickAwayListener onClickAway={() => setOpenSubtaskAssignee(true)}>
          {openSubtaskAssignee ? (
            <Avatar
              style={{ width: 26, height: 26, fontSize: 13 }}
              variant="rounded"
              onClick={() => {
                if (
                  props.userType === "owner" ||
                  props.userType === "collaborator"
                )
                  setOpenSubtaskAssignee(false);
              }}
            >
              {item.assignee != null ? (
                <Tooltip placement="top" title={item.assignee.username}>
                  <span>
                    {item.assignee.username
                      .split(" ")
                      .map(function (str) {
                        return str ? str[0].toUpperCase() : "";
                      })
                      .join("")}
                  </span>
                </Tooltip>
              ) : null}
            </Avatar>
          ) : (
            <motion.div
              initial={{ opacity: 0.5, width: 0 }}
              animate={{
                opacity: 1,
                width: "auto",
              }}
            >
              <Select
                placeholder="Assingee Name"
                bordered={false}
                className="w-100 radiusBorderInput"
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={async (val) => {
                  // onAssigneChange(val, index);
                  let res = await PATCH(ApiUrls.EDIT_SUBTASK + item._id, {
                    assign_person: val,
                  });

                  if (res.status == "200") {
                    refresh();
                  } else {
                    message.error(res.message);
                  }
                  setOpenSubtaskAssignee(true);
                }}
              >
                {users.map((item, index) => (
                  <Option
                    className="antSelect"
                    style={{ textTransform: "capitalize" }}
                    key={index}
                    value={item._id}
                  >
                    {item.username}
                  </Option>
                ))}
              </Select>
            </motion.div>
          )}
        </ClickAwayListener>
        <Tooltip title="Delete" placement="top">
          <DeleteOutlineOutlinedIcon
            style={{ color: "#e06666", fontSize: 24 }}
            onClick={async () => {
              if (
                props.userType === "owner" ||
                props.userType === "collaborator"
              ) {
                message.loading({ content: "Action in progress..", key });

                let resp = await PATCH(ApiUrls.DELETE_SUBTASK + item._id);

                if (resp.status == "200") {
                  refresh();
                  message.success({
                    content: resp.message,
                    key,
                    duration: 2.5,
                  });
                } else {
                  message.error(resp.message);
                }
              }
            }}
          />
        </Tooltip>
      </div>
    </List.Item>
  );
};
function ViewTaskDetails({
  open,
  onClose,
  data,
  allProjects,
  refreshTasks,
  selectedIdNUll,
  loggedUser,
  picture,
  ...props
}) {
  const drawerWidth = useMediaQuery("(max-width:575px)") ? "100%" : "65%";
  const [show, setShow] = React.useState(false);

  // console.log("This is the drawer Data", data);
  const [editTask, setEditTask] = useState({
    project: data.project?._id,
    title: data.title,
    start_date: data.start_date,
    due_date: data.due_date,
    assign_person: data.assign_person,
    description: data.description,
    label: data.label,
    status: data.status,
    label_color: data.label_color,
    isArchive: data.isArchive,
    tasklist: data.tasklist,
    assign_by: loggedUser._id,
    actual_hours: data.actual_hours,
    estimatedTime: data.estimatedTime,
  });

  const [subTasks, setSubTasks] = useState({
    value: "",
    sub_tasks: [],
  });
  const [showSubTask, setShowSubTask] = useState(false);
  const [showDependency, setShowDependency] = useState(false);
  const [taskDep, setTaskDep] = useState("parent_task");
  const [selectedDepTask, setSelectedDepTask] = useState("");
  const [allTasks, setAllTasks] = useState(null);
  const [commentVal, setCommentVal] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [addTaskList, setAddTaskList] = useState("");
  const [newTaskListArray, setNewTaskListArray] = useState([]);
  const [showTaskListInput, setShowTaskListInput] = useState(false);
  const [taskLists, setTaskLists] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [followers, setFollowers] = useState([]);
  const [openFollowers, setOpenFollowers] = useState(false);
  const [commentMentions, setCommentMentions] = useState([]);
  const [openComment, setOpenComment] = useState(false);
  const [uploadCommentAttachments, setUploadCommentAttachments] = useState([]);
  const [commentAttachments, setCommentAttachments] = useState([]);
  const [showTaskLabelInput, setShowTaskLabelInput] = useState(false);
  const [addTaskLabel, setAddTaskLabel] = useState("");
  const [allLabels, setAllLabels] = useState([]);
  const attachments = data.attachments.map((file) => ({
    ...file,
    url: baseeURL + file.url,
  }));

  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [totalElapsedTime, setTotalElapsedTime] = useState(null);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    getallUsers();
    return () => {};
  }, []);

  useEffect(() => {
    getLabels();
  }, [refresh]);

  useEffect(() => {
    getProjectTaskList();
    getProjectTasks();
    return () => {};
  }, [editTask.project]);

  useEffect(() => {
    getProjectFollowers();
    return () => {};
  }, [editTask.project, data.followers.length]);

  const getallUsers = async () => {
    let res = await GET(ApiUrls.GET_ALL_USERS);
    if (res.status == "200") {
      setAllUsers(res.users);
    }
  };
  const getProjectFollowers = async () => {
    let res = await GET(ApiUrls.GET_PROJECT_FOLLOWERS + editTask?.project);
    if (res.status == "200") {
      let newfollowers = res.followers.map((item) => {
        return {
          check: data.followers.some((fol) => {
            if (fol.follower._id === item._id) return true;
            else return false;
          }),
          ...item,
        };
      });
      setFollowers(newfollowers);
    }
  };
  const getProjectTasks = async () => {
    let res = await GET(
      ApiUrls.GET_PROJECT_TASKS_WITHOUT_LISTING +
        editTask?.project +
        `&status=active`
    );

    if (res.status == "200") {
      setAllTasks(res.tasks);
    }
  };
  const getProjectTaskList = async () => {
    let res = await GET(ApiUrls.GET_PROJECT_TASKLISTS + editTask.project);

    if (res.status == "200") {
      setTaskLists(res.tasklists);
    }
  };
  const getLabels = async () => {
    let res = await GET(ApiUrls.GET_ALL_LABLEL);
    if (res.status == "200") {
      setAllLabels(res.labels);
    }
  };

  if (data === undefined) return null;

  let tasksOptionMap = [
    ...data.dependency?.parent_task,
    ...data.dependency?.child_task,
  ];

  const handleSubmit = async () => {
    message.loading({ content: "Action in progress..", key });
    // onClose();
    let formData = null;
    let { assign_by, assign_person, ...EDITTASK } = editTask;

    formData = {
      ...EDITTASK,
      assign_person,
      assign_by,
    };
    let resp = await PATCH(ApiUrls.UPDATE_TASK + data._id, formData);
    if (resp.status == "200") {
      message.success({
        content: resp.message,
        key,
        duration: 2.5,
      });
      refreshTasks();
    } else {
      message.error({
        content: resp.message,
        key,
        duration: 2.5,
      });
    }
  };

  const handleSubtaskUpdate = (e, index) => {
    setSubTasks((prev) => {
      let sub_tasks = [...prev.sub_tasks];
      sub_tasks[index] = { ...sub_tasks[index] };
      sub_tasks[index].title = e.target.value;

      return { ...prev, sub_tasks };
    });
  };

  const fetchTotalElapsedTime = async () => {
    try {
      const response = await axios.get(
        `${baseeURL}/api/tasks/${data._id}/totalElapsedTime`
      );
      const { totalElapsedTime } = response.data;
      setTotalElapsedTime(totalElapsedTime);
    } catch (error) {
      console.error("Error fetching total elapsed time:", error);
    }
  };

  useEffect(() => {
    fetchTotalElapsedTime();

    const handleCustomEvent = () => {
      fetchTotalElapsedTime();
    };

    window.addEventListener("customEvent", handleCustomEvent);

    return () => {
      window.removeEventListener("customEvent", handleCustomEvent);
    };
  }, []);

  // =========================>> Manual Save Time
  const calculateElapsedTime = (startTime) => {
    const selectedTime = new Date(startTime);
    const hours = selectedTime.getHours().toString().padStart(2, "0");
    const minutes = selectedTime.getMinutes().toString().padStart(2, "0");
    const seconds = selectedTime.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const handleCustom = async (event) => {
    event.preventDefault();

    if (!selectedDateTime) {
      message.error("Date/time not selected.");
      return;
    }

    if (!selectedUser) {
      message.error("User not selected.");
      return;
    }

    const startTime = selectedDateTime.toISOString();
    const elapsedTime = calculateElapsedTime(selectedDateTime);
    const userId = selectedUser._id;

    try {
      const resp = await axios.patch(
        `${baseeURL}/api/tasks/${data._id}/timer/custom`,
        { startTime, userId, elapsedTime }
      );
      if (resp.status === 200) {
        message.success("Time saved successfully");
        fetchTotalElapsedTime();
        setSelectedDateTime(null);
        setSelectedUser(null);
      }
    } catch (error) {
      console.error("Failed to save time", error);
      message.error("Failed to save time");
    }
  };

  const handleUserSelect = (e) => {
    setSelectedUser(e.target.value);
  };

  const userList = (
    <Radio.Group
      onChange={handleUserSelect}
      value={selectedUser}
      style={{ display: "flex", flexDirection: "column" }} // Ensures column layout
    >
      {[...data.followers.map((f) => f.follower), data.assignee]
        .filter(Boolean)
        .map((user) => (
          <Radio key={user._id} value={user} style={{ marginBottom: 8 }}>
            {`${user.first_name} ${user.last_name} (${user.username})`}
          </Radio>
        ))}
    </Radio.Group>
  );

  return (
    <>
      <Drawer
        className="viewTask antModel drawerTitle"
        onClose={() => {
          onClose();
          if (
            data.status !== editTask.status ||
            data.isArchive !== editTask.isArchive
          ) {
            refreshTasks();
            selectedIdNUll();
          }
        }}
        visible={open}
        width={drawerWidth}
        placement="right"
        destroyOnClose={true}
        title={
          <div className="drawerTitle d-flex justify-content-between align-items-center">
            <Button
              variant="outlined"
              className={
                editTask.status == "active" ? "secondaryBtn" : "primaryBtn"
              }
              endIcon={<CheckCircleOutlineIcon />}
              onClick={async () => {
                setEditTask({
                  ...editTask,
                  status: editTask.status == "active" ? "complete" : "active",
                });

                let resp = await PATCH(ApiUrls.UPDATE_TASK + data._id, {
                  status: editTask.status == "active" ? "complete" : "active",
                });

                if (resp.status == "200") {
                  message.success("Task Status Changed Successfully.");
                } else {
                  message.error(resp.message);
                }
              }}
            >
              Mark Complete
            </Button>

            <motion.div
              className="d-flex flex-row iconsDiv"
              initial="hidden"
              variants={newList}
              animate={show ? "visible" : "hidden"}
            >
              <motion.div variants={newList}>
                <IconButton>
                  <CopyOutlined />
                </IconButton>
              </motion.div>

              <motion.div variants={newList}>
                <Tooltip
                  placement="top"
                  title={editTask.isArchive ? "Unarchive" : "Archive"}
                >
                  <IconButton
                    onClick={async () => {
                      setEditTask({
                        ...editTask,
                        isArchive: !editTask.isArchive,
                      });

                      let status =
                        editTask.isArchive === true ? "unarchive" : "archive";

                      message.loading({ content: "Action in progress..", key });

                      let res = await PATCH(ApiUrls.UPDATE_TASK + data._id, {
                        status: status,
                      });

                      if (res.status == "200") {
                        message.success({
                          content: res.message,
                          key,
                          duration: 2.5,
                        });
                      } else {
                        message.error(res.message);
                      }
                    }}
                  >
                    {editTask.isArchive ? <BiArchiveOut /> : <BiArchiveIn />}
                  </IconButton>
                </Tooltip>
              </motion.div>

              <motion.div variants={newList}>
                <IconButton>
                  <LinkOutlined />
                </IconButton>
              </motion.div>
            </motion.div>
          </div>
        }
      >
        <Form
          requiredMark={false}
          colon={false}
          scrollToFirstError={true}
          labelAlign="left"
          layout="horizantal"
          onFinish={handleSubmit}
          {...layout}
        >
          <Row>
            <div className="taskTitle">
              <Input
                className="w-100 taskTitle--input "
                required={true}
                placeholder="Task Title"
                bordered={false}
                defaultValue={data.title}
                onChange={(event) => {
                  setEditTask({
                    ...editTask,
                    title: event.target.value,
                  });
                }}
              />
            </div>

            <Col lg={6} md={6}>
              <Form.Item label="Assigned to">
                <Select
                  placeholder="Assingee Name"
                  bordered={false}
                  defaultValue={data.assignee?.username}
                  className="w-100"
                  onChange={(value) => {
                    setEditTask({
                      ...editTask,
                      assign_person: value,
                    });
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {allUsers.map((item, index) => (
                    <Option
                      className="antSelect"
                      style={{ textTransform: "capitalize" }}
                      key={index}
                      value={item._id}
                    >
                      {item.username}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Start Date">
                <DatePicker
                  bordered={false}
                  className="w-100"
                  defaultValue={
                    data.start_date ? moment(data.start_date) : null
                  }
                  onChange={(date, dateString) => {
                    setEditTask({
                      ...editTask,
                      start_date: dateString,
                    });
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Task Label"
                name="Task Label"
                initialValue={data.label}
              >
                <Select
                  placeholder="Task Label"
                  className="w-100"
                  bordered={false}
                  value={editTask.label}
                  onChange={(value) => {
                    const data = value.split(",");

                    setEditTask({
                      ...editTask,
                      label: data[0],
                      label_color: data[1],
                    });
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          padding: 8,
                        }}
                      >
                        <AnimatePresence>
                          {showTaskLabelInput && (
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                            >
                              <Input
                                style={{ flex: "auto" }}
                                suffix={
                                  <ColorPicker
                                    color={data.label_color}
                                    onChange={(val) =>
                                      setEditTask({
                                        ...editTask,
                                        label_color: val,
                                      })
                                    }
                                  />
                                }
                                addonAfter={
                                  <button
                                    onClick={async (e) => {
                                      e.preventDefault();
                                      // console.log("cliecked");
                                      if (addTaskLabel !== "") {
                                        let res = await POST(
                                          ApiUrls.ADD_LABEL,
                                          {
                                            title: addTaskLabel,
                                            color: editTask.label_color,
                                          }
                                        );
                                        // console.log(res);
                                        if (res.status === 200) {
                                          setRefresh(!refresh);
                                        }

                                        // setTaskLabels([
                                        //   ...taskLabels,
                                        //   addTaskLabel,
                                        // ]);
                                        setAddTaskLabel("");
                                        setShowTaskLabelInput(
                                          !showTaskLabelInput
                                        );
                                      }
                                    }}
                                  >
                                    Add
                                  </button>
                                }
                                value={addTaskLabel}
                                onChange={(e) => {
                                  setAddTaskLabel(e.target.value);
                                }}
                              />
                            </motion.div>
                          )}
                        </AnimatePresence>

                        {!showTaskLabelInput && (
                          <Button
                            style={{ fontSize: 12 }}
                            color="primary"
                            className="w-100"
                            onClick={() => {
                              if (showTaskLabelInput === false) {
                                setShowTaskLabelInput(!showTaskLabelInput);
                              }
                            }}
                          >
                            + Add Custom Label
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                >
                  {allLabels.map((item, index) => (
                    <Option
                      className="antSelect"
                      style={{ textTransform: "capitalize", color: item.color }}
                      key={index}
                      value={`${item.title},${item.color}`}
                    >
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Estimated Time">
                <Input
                  value={editTask ? editTask.estimatedTime : ""}
                  onChange={(e) => {
                    setEditTask({
                      ...editTask,
                      estimatedTime: e.target.value,
                    });
                  }}
                  placeholder="Estimated Time"
                  style={{ width: 200, height: 35, paddingLeft: 10 }}
                />
              </Form.Item>

              <Form.Item label="Followers" name="followers">
                {data.followers.length > 0 && (
                  <AntAvatar.Group
                    style={{ marginLeft: "18px" }}
                    maxCount={2}
                    size={30}
                    maxStyle={{
                      color: "#fff",
                      backgroundColor: "#2b7ae4",
                    }}
                  >
                    {data.followers.map((item, index) => (
                      <AntTooltip
                        key={index}
                        title={
                          (item.follower?.first_name || "") +
                            (item.follower?.last_name || "") ||
                          item.follower?.username
                        }
                        placement="top"
                      >
                        <AntAvatar
                          style={{
                            backgroundColor: ColorList[index],
                          }}
                        >
                          {(item.follower?.first_name || "") +
                            (item.follower?.last_name || "") ||
                            item.follower?.username}
                        </AntAvatar>
                      </AntTooltip>
                    ))}
                  </AntAvatar.Group>
                )}
                <Popover
                  color="transparent"
                  content={
                    <div className="followerDiv">
                      {followers.length > 0 ? (
                        followers.map((item, index) => (
                          <div key={index}>
                            <Checkbox
                              checked={item.check}
                              className="float-left mr-2"
                              onChange={async (e) => {
                                // console.log(e.target);
                                if (e.target.checked) {
                                  let res = await POST(ApiUrls.ADD_FOLLOWERS, {
                                    task_id: data._id,
                                    followers: [item.username],
                                  });
                                  if (res.status == "200") {
                                    setRefresh(!refresh);
                                    refreshTasks();
                                    message.success(res.message);
                                  } else {
                                    message.error(res.message);
                                  }
                                  // console.log(res);
                                } else {
                                  let res = await POST(
                                    ApiUrls.DELETE_FOLLOWERS,
                                    {
                                      task_id: data._id,
                                      followers: [item.username],
                                    }
                                  );
                                  //delete api
                                  // console.log(res);
                                  if (res.status == "200") {
                                    setRefresh(!refresh);
                                    refreshTasks();
                                    message.success(res.message);
                                  } else {
                                    message.error(res.message);
                                  }
                                }
                                // console.log(data.followers);
                              }}
                            ></Checkbox>
                            <span>
                              {(item?.first_name || "") +
                                (item?.last_name || "") || item?.username}
                            </span>
                          </div>
                        ))
                      ) : (
                        <Empty />
                      )}
                    </div>
                  }
                >
                  <IconButton
                    className="float-left"
                    color="primary"
                    onClick={() => {
                      setOpenFollowers(!openFollowers);
                    }}
                  >
                    <AiOutlineUsergroupAdd />
                  </IconButton>
                </Popover>
              </Form.Item>
            </Col>

            <Col lg={6} md={6}>
              <Form.Item label="Project">
                <Select
                  placeholder="Project Name"
                  bordered={false}
                  className="w-100"
                  defaultValue={data.project.projectName}
                  onChange={(value) => {
                    setEditTask({
                      ...editTask,
                      project: value,
                    });
                  }}
                >
                  {allProjects.map((item, index) => (
                    <Option
                      className="antSelect"
                      style={{ textTransform: "capitalize" }}
                      key={index}
                      value={item._id}
                    >
                      {item.projectName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Due Date">
                <DatePicker
                  bordered={false}
                  className="w-100"
                  defaultValue={data.due_date ? moment(data.due_date) : null}
                  onChange={(date, dateString) => {
                    setEditTask({
                      ...editTask,
                      due_date: dateString,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Task List"
                name="Task List"
                rules={[
                  {
                    required: true,
                    message: "Please Select Task List!",
                  },
                ]}
                initialValue={`${data.tasklist}-tasklist`}
              >
                <Select
                  className="w-100"
                  bordered={false}
                  placeholder="Select Task List"
                  // defaultValue={`${data.tasklist}-tasklist`}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={(e) => {
                    const [val, key] = e.split("-");
                    let { tasklist, new_tasklist, ...taskDetail } = editTask;

                    setEditTask({ ...taskDetail, [key]: val });
                  }}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          padding: 8,
                        }}
                      >
                        <AnimatePresence>
                          {showTaskListInput && (
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                            >
                              <Input
                                style={{ flex: "auto" }}
                                addonAfter={
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();

                                      if (addTaskList !== "") {
                                        setNewTaskListArray([
                                          ...newTaskListArray,
                                          addTaskList,
                                        ]);
                                        setAddTaskList("");
                                        setShowTaskListInput(
                                          !showTaskListInput
                                        );
                                        setShowTaskListInput(
                                          !showTaskListInput
                                        );
                                      }
                                    }}
                                  >
                                    Add
                                  </button>
                                }
                                value={addTaskList}
                                onChange={(e) => {
                                  setAddTaskList(e.target.value);
                                }}
                              />
                            </motion.div>
                          )}
                        </AnimatePresence>

                        {!showTaskListInput && (
                          <Button
                            style={{ fontSize: 12 }}
                            color="primary"
                            onClick={() => {
                              if (showTaskListInput === false) {
                                setShowTaskListInput(!showTaskListInput);
                              }
                            }}
                          >
                            + Add new task list
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                >
                  {taskLists.map((item, index) => (
                    <Option
                      className="antSelect"
                      key={index}
                      value={`${item._id}-tasklist`}
                    >
                      {item.title}
                    </Option>
                  ))}
                  {newTaskListArray.map((item, index) => (
                    <Option key={index} value={`${item}-new_tasklist`}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <div className="p-2 rounded-lg">
                <span className="text-gray font-semibold mb-2">
                  Actual time
                </span>
                <div className="flex items-center gap-4">
                  <div className="w-60 bg-gray-100 rounded-lg p-2 border">
                    <span className="text-gray-600 font-semibold">
                      {totalElapsedTime}
                    </span>
                  </div>
                  <TimerButton
                    taskId={data._id}
                    assignee={data.assignee?._id}
                    // assignee={data.assignee?.username}
                    followers={data.followers}
                    // loginUser={loggedUser.username}
                    loginUser={loggedUser._id}
                  />
                </div>
              </div>

              <div>
                {(loggedUser?.role?.title === "collaborator" ||
                  loggedUser?.role?.title === "owner") && (
                  <div className="p-2 rounded-lg">
                    <span className="text-gray font-semibold mb-2">
                      Manual Time
                    </span>
                    <div className="flex items-center gap-5">
                      <DatePicker
                        showTime={{ format: "HH:mm" }}
                        placeholder="Select Date & Time"
                        style={{ width: 250, height: 40, paddingLeft: 10 }}
                        format="YYYY-MM-DD HH:mm"
                        disabledDate={(current) =>
                          current && current > moment().endOf("day")
                        }
                        onChange={(date) => setSelectedDateTime(date)}
                        value={selectedDateTime}
                      />
                      <Popover
                        content={userList}
                        title="Select User"
                        trigger="click"
                        visible={popoverVisible}
                        onVisibleChange={(visible) =>
                          setPopoverVisible(visible)
                        }
                      >
                        <AiOutlineUsergroupAdd
                          className="gap-2"
                          size={28}
                          color="#2B7AE4"
                        />
                      </Popover>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCustom}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              {/* {(loggedUser?.role?.title === "collaborator" ||
                loggedUser?.role?.title === "owner") && (
                <div className="p-2 rounded-lg">
                  <span className="text-gray font-semibold mb-2">
                    Manual Time
                  </span>
                  <div className="flex items-center gap-3 ">
                    <DatePicker
                      showTime={{ format: "HH:mm" }}
                      placeholder="Select Date & Time"
                      style={{ width: 250, height: 40, paddingLeft: 10 }}
                      format="YYYY-MM-DD HH:mm"
                      disabledDate={(current) =>
                        current && current > new Date()
                      }
                      onChange={(date) => setSelectedDateTime(date)}
                      value={selectedDateTime}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(event) => handleCustom(event)}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              )} */}
            </Col>
          </Row>
          <Divider className="mb-3" />

          <RBForm.Group className=" position-relative">
            <RBForm.Label>Description</RBForm.Label>
            <InlineToolbarTextEditor
              returnHtml={(html) => {
                setEditTask({
                  ...editTask,
                  description: html,
                });
              }}
              initialState={data.description}
            />
          </RBForm.Group>
          <UploadFile
            key={data.attachments}
            onChange={async (info) => {
              if (info.file.status == "done") {
                let formData = new FormData();
                formData.append(`attachments`, info.file.originFileObj);
                formData.append("project_id", data.project._id);
                formData.append("task_id", data._id);
                let resp = await AXIOS_POST(ApiUrls.ADD_ATTACHMENT, formData);

                if (resp.status == "200") {
                  message.success("Files added successfully.");
                  refreshTasks();
                }
              }
            }}
            defaultFileList={attachments}
            onPreview={async (file) => {
              window.open(file.url, "_blank");
            }}
            onRemove={async (file) => {
              let resp = await PATCH(ApiUrls.DELETE_ATTACHMENT + file._id);

              if (resp.status == "200") {
                message.success("File Deleted Successfully.");
              } else {
                message.error(resp.message);
              }
            }}
          />

          <Divider className="my-3" />
          {showSubTask ? (
            <div className="subtask--mainDiv">
              <h2>Subtasks</h2>
              <div className="subtaskFormDiv">
                <Input
                  className="w-100 radiusBorderInput "
                  size="large"
                  autoFocus={true}
                  placeholder="Name of subtask"
                  value={subTasks.value}
                  onChange={async (e) => {
                    setSubTasks({ ...subTasks, value: e.target.value });
                  }}
                  onPressEnter={async (event) => {
                    event.preventDefault();
                    if (subTasks.value !== "") {
                      setSubTasks({
                        value: "",
                      });

                      let resp = await POST(ApiUrls.ADD_SUBTASK, {
                        title: subTasks.value,
                        main_task: data._id,
                      });

                      if (resp.status == "200") refreshTasks();
                    }
                  }}
                />
                <div className="mt-3">
                  <Button
                    className="rounded-pill mr-2"
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      if (subTasks.value !== "") {
                        setSubTasks({
                          value: "",
                        });
                        let resp = await POST(ApiUrls.ADD_SUBTASK, {
                          title: subTasks.value,
                          main_task: data._id,
                        });

                        if (resp.status == "200") refreshTasks();
                      }
                    }}
                  >
                    Add
                  </Button>
                  <Button
                    className="rounded-pill"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setShowSubTask(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          ) : props.userType === "owner" ||
            props.userType === "collaborator" ? (
            <AnimatedBtn
              label="Add a Subtask"
              onClick={() => {
                setShowSubTask(true);
              }}
            />
          ) : null}

          {/* View props subtasks */}
          {data.sub_tasks.length > 0 ? (
            <List itemLayout="horizontal" dataSource={data.sub_tasks}>
              {data.sub_tasks.map((item, index) => (
                <ListItem
                  key={index}
                  item={item}
                  index={index}
                  onChange={handleSubtaskUpdate}
                  users={allUsers}
                  userType={props.userType}
                  refresh={refreshTasks}
                />
              ))}
            </List>
          ) : null}

          <Divider className="my-3" />
          {data.dependency.parent_task.length > 0 ? (
            <div className="dependency--maindiv">
              <h2>Parent Tasks</h2>
              <List
                size="small"
                dataSource={data.dependency.parent_task}
                renderItem={(item) => (
                  <List.Item
                    className="listItem"
                    actions={[
                      <Tooltip
                        key="delete-child-dependency"
                        title="Delete"
                        placement="top"
                      >
                        <DeleteOutlineOutlinedIcon
                          onClick={async () => {
                            let res = await PATCH(
                              ApiUrls.DELETE_DEPENDENCY + item.dependency_id
                            );

                            if (res.status == "200") {
                              refreshTasks();
                            } else {
                              message.error(res.message);
                            }
                          }}
                          style={{ color: "#e06666", fontSize: 24 }}
                        />
                      </Tooltip>,
                    ]}
                  >
                    {item.title}
                  </List.Item>
                )}
              />
            </div>
          ) : null}
          {data.dependency.child_task.length > 0 ? (
            <div className="dependency--maindiv">
              <h2>Child Tasks</h2>
              <List
                size="small"
                dataSource={data.dependency.child_task}
                renderItem={(item) => (
                  <List.Item
                    className="listItem"
                    actions={[
                      <Tooltip
                        key="delete-child-dependency"
                        title="Delete"
                        placement="top"
                      >
                        <DeleteOutlineOutlinedIcon
                          onClick={async () => {
                            let res = await PATCH(
                              ApiUrls.DELETE_DEPENDENCY + item.dependency_id
                            );

                            if (res.status == "200") {
                              refreshTasks();
                            } else {
                              message.error(res.message);
                            }
                          }}
                          style={{ color: "#e06666", fontSize: 24 }}
                        />
                      </Tooltip>,
                    ]}
                  >
                    {item.title}
                  </List.Item>
                )}
              />
            </div>
          ) : null}

          {showDependency ? (
            <div className="dependency--maindiv">
              <h2>Dependency</h2>
              <div className="dependencyFormDiv">
                <Radio.Group
                  name="radiogroup"
                  defaultValue={"parent_task"}
                  onChange={(e) => {
                    setTaskDep(e.target.value);
                  }}
                >
                  <Radio value={"parent_task"}>Parent Task</Radio>
                  <Radio value={"child_task"}>Child Task</Radio>
                </Radio.Group>
                <br />
                <Select
                  key={taskDep}
                  placeholder="Search For a Task..."
                  className="w-100 my-3 radiusBorderInput"
                  bordered={false}
                  style={{ width: 120 }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={(value) => {
                    setSelectedDepTask(value);
                  }}
                >
                  {allTasks
                    .filter((task) => {
                      return (
                        tasksOptionMap.every((item) => item._id !== task._id) &&
                        task._id !== data._id
                      );
                    })
                    .map((task, index) => {
                      return (
                        <Option
                          className="antSelect"
                          key={index}
                          value={task._id}
                        >
                          {task.title}
                        </Option>
                      );
                    })}
                </Select>
                <p>
                  Parent tasks should be completed before the current task.
                  Member assigned to the current task will be notified when each
                  parent task is finished.
                </p>
                <div>
                  <Button
                    className="rounded-pill mr-2"
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      if (taskDep === "parent_task") {
                        let newArray = [
                          ...data.dependency.parent_task,
                          ...data.dependency.child_task,
                        ];

                        let postData = newArray.every((task) => {
                          return task._id !== selectedDepTask;
                        });

                        setShowDependency(false);
                        if (postData) {
                          let resp = await POST(ApiUrls.ADD_DEPENDENCY, {
                            task_id: data._id,
                            parent_task: selectedDepTask,
                          });

                          if (resp.status == "200") {
                            refreshTasks();
                          } else {
                            message.error(resp.message);
                          }
                        }
                      } else if (taskDep === "child_task") {
                        let newArray = [
                          ...data.dependency.parent_task,
                          ...data.dependency.child_task,
                        ];

                        let postData = newArray.every((task) => {
                          return task._id !== selectedDepTask;
                        });

                        setShowDependency(false);
                        if (postData) {
                          let resp = await POST(ApiUrls.ADD_DEPENDENCY, {
                            task_id: data._id,
                            child_task: selectedDepTask,
                          });

                          if (resp.status == "200") {
                            refreshTasks();
                          } else {
                            message.error(resp.message);
                          }
                        }
                      }
                    }}
                  >
                    Add
                  </Button>
                  <Button
                    className="rounded-pill"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setShowDependency(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          ) : props.userType === "owner" ||
            props.userType === "collaborator" ? (
            <AnimatedBtn
              label="Add a Dependency"
              onClick={() => {
                setShowDependency(true);
              }}
            />
          ) : null}
          <Divider className="my-3" />

          <h3 className="comentTitle">Discussions</h3>

          {data.comments.length > 0 && <CommentList comments={data.comments} />}
          <Comment
            avatar={<Avatar alt="user" src={picture} />}
            content={
              openComment ? (
                <>
                  <div className="customEditor">
                    <Mentions
                      autoFocus={true}
                      value={commentVal}
                      onChange={(value) => {
                        setCommentVal(value);
                      }}
                      onSelect={(user) => {
                        if (!commentMentions.includes(user.value)) {
                          setCommentMentions([...commentMentions, user.value]);
                        }
                      }}
                      rows={4}
                    >
                      {followers.map((item, index) => (
                        <Mentions.Option key={index} value={item.username}>
                          {(item?.first_name || "") + (item?.last_name || "") ||
                            item?.username}
                        </Mentions.Option>
                      ))}
                    </Mentions>
                    <div className="editorAttachments">
                      <Upload
                        listType="picture"
                        className="w-100"
                        name="commentAttachments"
                        fileList={uploadCommentAttachments}
                        maxCount={10}
                        multiple={true}
                        customRequest={({ file, onSuccess }) => {
                          setTimeout(() => {
                            onSuccess("ok");
                          }, 0);
                        }}
                        onChange={(info) => {
                          // if (info.file.status !== "uploading") {}
                          // console.log(info);
                          if (info.fileList.length > 0) {
                            // console.log("if inside  upload");
                            setUploadCommentAttachments(info.fileList);
                            setCommentAttachments(() =>
                              info.fileList.map((file) => file.originFileObj)
                            );
                          } else {
                            setUploadCommentAttachments([]);
                            setCommentAttachments([]);
                          }
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          <div className="content">
                            <span className="title">Attachments</span>
                            <span className="detail">
                              (you can click to select attachments)
                            </span>
                          </div>
                          <Button
                            color="primary"
                            variant="outlined"
                            className="rounded-pill"
                          >
                            Upload File
                          </Button>
                        </div>
                      </Upload>
                    </div>
                  </div>
                  <div className="mt-3 ">
                    <Button
                      variant="outlined"
                      className="rounded-pill mr-2"
                      onClick={async () => {
                        if (
                          commentVal === "" &&
                          commentAttachments.length > 0
                        ) {
                          message.error(
                            "Please write a comment before uploading attachments."
                          );
                          return;
                        }
                        try {
                          let commentStr = commentVal;
                          setCommentVal("");
                          setCommentMentions([]);
                          setUploadCommentAttachments([]);
                          setCommentAttachments([]);
                          if (commentVal !== "") {
                            let formData = new FormData();
                            if (commentAttachments.length > 0) {
                              for (
                                let i = 0;
                                i < commentAttachments.length;
                                i++
                              ) {
                                formData.append(
                                  `attachments`,
                                  commentAttachments[i]
                                );
                              }
                            }
                            formData.append("message", commentStr);
                            formData.append(
                              "sending_time",
                              moment().format("YYYY-MM-DD")
                            );
                            formData.append("sender_id", loggedUser._id);
                            formData.append("task_id", data._id);
                            formData.append(
                              "tagged",
                              JSON.stringify(commentMentions)
                            );
                            formData.append("project_id", data.project._id);

                            let res = await AXIOS_POST(
                              ApiUrls.ADD_COMMENTS,
                              formData
                            );
                            // console.log(res);
                            if (res.status == 200) {
                              setTimeout(() => {
                                refreshTasks();
                              }, 500);
                            } else {
                              message.error(res.data.message);
                            }
                          }
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                    >
                      Add Comment
                    </Button>
                    <Button
                      variant="outlined"
                      className="rounded-pill"
                      onClick={() => {
                        setCommentVal("");
                        setCommentMentions([]);
                        setUploadCommentAttachments([]);
                        setCommentAttachments([]);
                        setOpenComment(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              ) : (
                <div
                  onClick={() => {
                    setOpenComment(!openComment);
                  }}
                  className="showComment"
                >
                  Write a comment...
                </div>
              )
            }
          />
          {props.userType === "owner" || props.userType === "collaborator" ? (
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className="float-right"
            >
              Update
            </Button>
          ) : null}
        </Form>
      </Drawer>
    </>
  );
}

export default ViewTaskDetails;
