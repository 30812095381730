/*eslint-disable*/
import Icon from "@ant-design/icons";
import { Button, Chip, IconButton, Tooltip } from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import {
  Dropdown,
  Empty,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Popover,
  Select,
} from "antd";
import NewTaskDialog from "components/Create/NewTaskDialog";
import BackdropLoading from "components/Loading/BackdropLoading";
import ViewTaskDetails from "components/TaskComponents/ViewTaskDetails";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import ApiUrls from "utils/ApiUrls";
import { GET, PATCH, POST } from "utils/Functions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TimerButton from "components/TrackTime/TimerButton";

const settingMenuOptions = [
  "duplicate",
  "archive",
  "unarchive",
  "active",
  "complete",
];
const key = "updatable";
const taskFilterOptions = ["active", "complete", "archive"];

const { Option } = Select;
const taskTitle = {
  hover: {
    color: "#2b7ae4",
    fontWeight: "600",

    cursor: "pointer",
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeIn",
    },
  },
};
const arrowDown = () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.41 0.294922L6 4.87492L10.59 0.294922L12 1.70492L6 7.70492L0 1.70492L1.41 0.294922Z" />
  </svg>
);
const ExpandMoreIcon = (props) => <Icon component={arrowDown} {...props} />;

function ProjectTasks(props) {
  const [openNewTaskDialogBox, setOpenNewTaskDialogBox] = useState(false);
  const [openTaskDrawer, setOpenTaskDrawer] = React.useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [projectFilter, setProjectFilter] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [usernameFilter, setUsernameFilter] = useState();
  const [taskList, setTaskList] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const drawerRef = useRef();
  const isFirstRun = useRef(true);
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({
    allTasks: [],
    allClients: [],
    allProjects: [],
    allUsers: [],
  });
  const [paginate, setPaginate] = useState({
    pageSize: 0,
    currentPage: null,
    totalRecord: null,
  });

  const [taskStatusFilter, setTaskStatusFilter] = useState(
    taskFilterOptions[0]
  );

  const [newTaskList, setNewTaskList] = useState({
    project_id: props.location?.state._id,
  });

  useEffect(() => {
    getProjectTasks();
    getAllData();
  }, [refresh]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    filterTasks();
  }, [projectFilter, taskStatusFilter]);

  /* Start  Pagination data  */

  // const handlePageChange = async (page) => {
  //   let res = await GET(
  //     ApiUrls.GET_ALL_TASKS +
  //       `status=${taskStatusFilter}&id=${projectFilter}&page=${page}`
  //   );
  //   console.log(res, "resp");
  //   if (res.status == "200") {
  //     setPaginate({
  //       pageSize: res.per_page,
  //       totalRecord: res.total,
  //       currentPage: res.current_page,
  //     });
  //     setData({
  //       ...data,
  //       allTasks: res.tasks,
  //     });
  //   }
  // };

  /* End  Pagination data  */

  const getAllData = async () => {
    let resProject = await GET(ApiUrls.GET_ALL_PROJECTS + `status=active`);
    let resClient = await GET(ApiUrls.GET_ALL_CLIENTS);
    let resUsers = await GET(ApiUrls.GET_ALL_USERS);

    if (
      resProject.status == "200" &&
      resClient.status == "200" &&
      resUsers.status == "200"
    ) {
      setData({
        ...data,
        allProjects: resProject.projects,
        allClients: resClient.clients,
        allUsers: resUsers.users,
      });
    }
  };

  const getProjectTasks = async (username) => {
    setLoading(true);
    let res = await GET(
      ApiUrls.GET_PROJECT_TASKS +
        props.location?.state._id +
        `&status=${taskStatusFilter}`
    );

    if (res.status == "200") {
      setPaginate({
        pageSize: res.per_page,
        totalRecord: res.total,
        currentPage: res.current_page,
      });
      setAllTasks(res.tasks[0].tasklists);
      setTaskList(res.tasks);
      setLoading(false);
    }
  };

  const filterTasks = async () => {
    setLoading(true);
    let res = await GET(
      ApiUrls.GET_PROJECT_TASKS +
        props.location?.state._id +
        `&status=${taskStatusFilter}`
    );

    if (res.status == "200") {
      setPaginate({
        pageSize: res.per_page,
        totalRecord: res.total,
        currentPage: res.current_page,
      });
      setAllTasks(res.tasks[0].tasklists);
      setTaskList(res.tasks);
      setLoading(false);
    }
  };
  const forceCompleteTask = async (status, taskID) => {
    let res = await PATCH(ApiUrls.FORCE_UPDATE_TASK + taskID, {
      status: status,
    });
    if (res.status == "200") {
      message.success(res.message);

      setRefresh(!refresh);
    } else {
      message.error(res.message);
    }
  };
  const changeTaskStatus = async (status, taskID) => {
    message.loading({ content: "Action in progress..", key });

    let res = await PATCH(ApiUrls.UPDATE_TASK + taskID, {
      status: status,
    });
    if (res.status == "200") {
      message.success({
        content: res.message,
        key,
        duration: 2.5,
      });
      setRefresh(!refresh);
    } else {
      if (res.hasOwnProperty("dependency_error")) {
        Modal.confirm({
          title: "Mark as Complete?",
          centered: true,
          content: res.dependency_error,
          okText: "Ignore and mark as Complete",
          cancelText: "Cancel",
          destroyOnClose: true,
          okButtonProps: {
            className: "rounded-pill",
          },
          cancelButtonProps: { className: "rounded-pill" },
          maskStyle: {
            backgroundColor: "rgba(251, 251, 251, 0.8)",
          },
          onOk: () => forceCompleteTask(status, taskID),
        });
      } else {
        message.error(res.message);
      }
    }
  };
  const SettingMenu = (id, index, status, isArchived) => (
    <Menu>
      {settingMenuOptions
        .filter((st) => {
          if (st == "archive") return !isArchived;
          if (st == "unarchive") return isArchived;
          if (!isArchived) return st !== status;
        })
        .map((val) => (
          <Menu.Item
            key={val}
            onClick={(e) => {
              changeTaskStatus(e.key, id);
            }}
          >
            {val}
          </Menu.Item>
        ))}

      <Menu.Item onClick={() => deleteTask(id)}>{"Delete"}</Menu.Item>
    </Menu>
  );
  const forceDeleteTask = async (taskId) => {
    let res = await PATCH(ApiUrls.FORCE_DELETE_TASK + taskId);
    if (res.status == "200") {
      message.success(res.message);
      setRefresh(!refresh);
    } else {
      message.error(res.message);
    }
  };

  const deleteTask = async (taskId) => {
    message.loading({ content: "Action in progress..", key });

    let res = await PATCH(ApiUrls.DELETE_TASK + taskId);
    if (res.status == "200") {
      message.success({
        content: res.message,
        key,
        duration: 2.5,
      });
      setRefresh(!refresh);
    } else {
      if (res.hasOwnProperty("dependency_error")) {
        Modal.confirm({
          title: "Delete Task?",
          centered: true,
          content: res.dependency_error,
          okText: "Delete and Disconnect Dependency",
          cancelText: "Cancel",
          destroyOnClose: true,
          width: 500,
          okButtonProps: { className: "rounded-pill" },
          cancelButtonProps: { className: "rounded-pill" },
          maskStyle: {
            backgroundColor: "rgba(251, 251, 251, 0.8)",
          },
          onOk: () => forceDeleteTask(taskId),
        });
      } else {
        message.error(res.message);
      }
    }
  };

  const handleDrawer = () => {
    setOpenTaskDrawer(!openTaskDrawer);
  };
  const handleSubmit = async () => {
    let resp = await POST(ApiUrls.ADD_NEW_TASKLIST, newTaskList);
    form.resetFields();
    if (resp.status == "200") {
      message.success(resp.message);
      setRefresh(!refresh);
    } else {
      message.error(resp.message);
    }
  };
  const TableRow = ({ item, index, selectedIndex }) => (
    <tr
      onClick={() => {
        const projectIndex = taskList.findIndex(
          (project) => project._id === item.project._id
        );
        const taskListIndex = taskList[projectIndex].tasklists.findIndex(
          (list) => list._id === item.tasklist
        );
        const taskIndex = taskList[projectIndex].tasklists[
          taskListIndex
        ].tasks.findIndex((task) => task.title === item.title);

        setSelectedID({
          project: projectIndex,
          taskList: taskListIndex,
          task: taskIndex,
        });

        handleDrawer();
      }}
    >
      <motion.td
        style={{
          maxWidth: "100px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        initial="rest"
        whilehover="hover"
        animate="rest"
        onClick={(e) => e.stopPropagation()}
      >
        <Tooltip placement="top" title={item.title}>
          <motion.span
            variants={taskTitle}
            onClick={() => {
              const projectIndex = taskList.findIndex(
                (project) => project._id === item.project._id
              );
              const taskListIndex = taskList[projectIndex].tasklists.findIndex(
                (list) => list._id === item.tasklist
              );
              const taskIndex = taskList[projectIndex].tasklists[
                taskListIndex
              ].tasks.findIndex((task) => task.title === item.title);
              // console.log("Required indexes-->",{project:projectIndex , tasklist:taskListIndex , taskIndex:taskIndex})

              setSelectedID({
                project: projectIndex,
                taskList: taskListIndex,
                task: taskIndex,
              });
              handleDrawer();
            }}
          >
            {item.title}
          </motion.span>
        </Tooltip>
      </motion.td>

      <td>
        {(item.project.client?.first_name || "") +
          (item.project.client?.last_name || "") ||
          item.project.client?.username}
      </td>
      <td>{item.project.projectName}</td>
      <td>
        {item.label !== undefined ? (
          <Chip
            style={{
              background: item?.label_color,
              color: "#Fff",
              minWidth: 80,
            }}
            label={item.label}
          />
        ) : (
          "----"
        )}
      </td>
      <td>
        {(item.assignee?.first_name || "") + (item.assignee?.last_name || "") ||
          item.assignee?.username}
      </td>
      <td>{moment(item.due_date).format("LL")}</td>

      {/* <td onClick={(e) => e.stopPropagation()}>
        <TimerButton
          taskId={item._id}
          assignee={item.assignee?._id}
          followers={item.followers}
          loginUser={props.loginUserInfo?._id}
        />
      </td> */}

      <td onClick={(e) => e.stopPropagation()}>
        <Dropdown
          trigger={["click"]}
          overlay={SettingMenu(item._id, index, item.status, item.isArchive)}
          placement="bottom"
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          <IconButton
            style={{
              padding: "3px",
            }}
          >
            <SettingsOutlinedIcon style={{ fill: "#DADADA" }} />
          </IconButton>
        </Dropdown>
      </td>
    </tr>
  );

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      allTasks,
      result.source.index,
      result.destination.index
    );

    setAllTasks(items);
  }

  const [form] = Form.useForm();

  const content = (
    <div>
      <Form
        form={form}
        preserve={false}
        requiredMark={false}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="taskList"
          label="Task List Name"
          rules={[
            {
              required: true,
              message: "Please Input Task list name!",
            },
          ]}
        >
          <Input
            bordered={false}
            className="radiusBorderInput"
            onChange={(e) => {
              setNewTaskList({ ...newTaskList, title: e.target.value });
            }}
          />
        </Form.Item>

        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            endIcon={<AddCircleOutlineOutlinedIcon />}
            variant="contained"
            type="submit"
            color="primary"
          >
            Create
          </Button>
        </div>
      </Form>
    </div>
  );

  const [expanded, setExpanded] = useState(null);

  const Accordion = ({ i, list, taskListIndex }) => {
    const isOpen = i === expanded;
    const userTasks = usernameFilter
      ? list.tasks.filter((task) => task.assignee?.username === usernameFilter)
      : list.tasks;
    const taskCount = userTasks.length;

    if (usernameFilter && taskCount === 0) {
      return null;
    }

    return (
      <div className="collapseContainer">
        <motion.header
          initial={false}
          className="collapseTitle"
          onClick={() => {
            setExpanded(isOpen ? false : i);
            if (isOpen) {
              document
                .getElementById(
                  `collapse--${list._id}--icon` + `-${taskListIndex}`
                )
                .classList.remove("-rotate-90");
              document
                .getElementById(
                  `collapse--${list._id}--icon` + `-${taskListIndex}`
                )
                .classList.add("rotate-0");
            } else {
              document
                .getElementById(
                  `collapse--${list._id}--icon` + `-${taskListIndex}`
                )
                .classList.remove("rotate-0");
              document
                .getElementById(
                  `collapse--${list._id}--icon` + `-${taskListIndex}`
                )
                .classList.add("-rotate-90");
            }
          }}
        >
          <ExpandMoreIcon
            id={`collapse--${list._id}--icon` + `-${taskListIndex}`}
            className={
              isOpen
                ? "transform duration-500 ease-in-out mr-2 position-relative -top-0.5 rotate-0"
                : "transform duration-500 ease-in-out mr-2 position-relative -top-0.5 -rotate-90"
            }
            style={{ marginTop: 5 }}
          />
          <span className="collapseSubHeading">
            {list.title}: {taskCount}
          </span>
        </motion.header>
        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.section
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <div className="customTable" style={{ margin: "0px" }}>
                <Table responsive borderless hover>
                  <thead>
                    <tr className="tableHeading">
                      <th scope="col">Task </th>
                      <th scope="col">Client</th>
                      <th scope="col">Project </th>
                      <th scope="col">Task Label </th>
                      <th scope="col">Assigned to</th>
                      <th scope="col">Due Date </th>
                      <th scope="col">Actions </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {userTasks.map((task, taskIndex) => (
                      <TableRow
                        selectedIndex={{
                          projectIndex: i,
                          taskListIndex,
                        }}
                        key={taskIndex}
                        item={task}
                        index={taskIndex}
                      />
                    ))}
                  </tbody>
                </Table>
              </div>
            </motion.section>
          )}
        </AnimatePresence>
      </div>
    );
  };

  return (
    <>
      <div className="taskButtons">
        {openNewTaskDialogBox ? (
          <NewTaskDialog
            ref={drawerRef}
            refreshAfterSubmit={() => {
              setRefresh(!refresh);
            }}
            openModal={openNewTaskDialogBox}
            handleClose={() => {
              drawerRef.current.handleClose();
            }}
            closeModal={() => setOpenNewTaskDialogBox(false)}
            projectID={props.location?.state}
            allProjects={data.allProjects}
            loggedUser={props.loginUserInfo}
            picture={props.picture}
          />
        ) : null}
        <Select
          allowClear={true}
          placeholder="Active Tasks"
          className="mr-2 filterButton"
          bordered={false}
          listItemHeight={10}
          listHeight={250}
          onChange={(value) => {
            if (value !== undefined) setTaskStatusFilter(value);
            else setTaskStatusFilter("");
          }}
          defaultValue="Active Tasks"
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          {taskFilterOptions.map((val, index) => (
            <Option
              className="antSelect"
              style={{ textTransform: "capitalize" }}
              key={index}
              value={val}
            >
              {val}
            </Option>
          ))}
        </Select>

        {props.userType === "owner" || props.userType === "collaborator" ? (
          <>
            <Select
              allowClear={true}
              placeholder="Select User"
              className=" ml-2 filterButton w-64"
              bordered={false}
              listItemHeight={10}
              listHeight={250}
              onChange={(value) => {
                if (value !== undefined) setUsernameFilter(value);
                else setUsernameFilter("");
              }}
              defaultValue={"All Users"}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              <Option
                className="antSelect"
                style={{ textTransform: "capitalize" }}
                value=""
              >
                {"All Users"}
              </Option>
              {data.allUsers.map((user, index) => (
                <Option
                  className="antSelect"
                  style={{ textTransform: "capitalize" }}
                  key={index}
                  value={user.username}
                >
                  {user.first_name} {user.last_name} ({user.username})
                </Option>
              ))}
            </Select>
            <Button
              onClick={() => {
                setOpenNewTaskDialogBox(!openNewTaskDialogBox);
              }}
              endIcon={<AddCircleOutlineOutlinedIcon />}
              variant="contained"
              className="float-right taskButton"
              id="floatingButton"
              color="primary"
            >
              New Task
            </Button>
            <Popover
              content={content}
              title="Add New Task List"
              placement="bottom"
              trigger="click"
            >
              <Button
                endIcon={<AddCircleOutlineOutlinedIcon />}
                variant="contained"
                className="float-right taskButton mr-2"
                color="primary"
              >
                New Task List
              </Button>
            </Popover>
          </>
        ) : null}
      </div>

      {loading ? (
        <BackdropLoading loading={loading} />
      ) : allTasks.length < 1 ? (
        <div className="container-fluid d-flex justify-content-center align-items-center mt-5">
          <Empty
            description={<span>No Tasks to show...</span>}
            className="w-100 "
          />
        </div>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {allTasks.map((list, taskListIndex) => (
                  <Draggable
                    key={list._id}
                    draggableId={list._id}
                    index={taskListIndex}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={taskListIndex}
                      >
                        <Accordion
                          key={taskListIndex}
                          i={taskListIndex}
                          taskListIndex={taskListIndex}
                          list={list}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {loading === false && allTasks.length >= 0 && selectedID !== null ? (
        <ViewTaskDetails
          key={selectedID}
          selectedIdNUll={() => {
            setSelectedID(null);
          }}
          data={allTasks[selectedID.taskList].tasks[selectedID.task]}
          open={openTaskDrawer}
          loggedUser={props.loginUserInfo}
          picture={props.picture}
          userType={props.userType}
          onClose={() => {
            handleDrawer();
            setSelectedID(null);
          }}
          allProjects={data.allProjects}
          refresh={refresh}
          refreshTasks={() => {
            setRefresh(!refresh);
          }}
        />
      ) : null}
    </>
  );
}
const mapStatetoProps = (state) => {
  return {
    picture: state.Login.picture,

    loginUserInfo: state.Login.user_info,
    userType: state.Login.user_info?.role?.title,
  };
};
export default connect(mapStatetoProps)(ProjectTasks);
