import React, { useEffect, useState } from "react";
import { Table, Empty, Button, Input, Spin } from "antd";
import { GET } from "utils/Functions";
import ApiUrls from "utils/ApiUrls";
import moment from "moment";
import TimerButton from "components/TrackTime/TimerButton";

const LiveTracking = (props) => {
  const [allUsers, setAllUsers] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [allTasks]);

  const getAllData = async () => {
    setLoading(true);
    try {
      const [resUsers, resTasks, completeTasks] = await Promise.all([
        GET(ApiUrls.GET_ALL_USERS),
        GET(ApiUrls.GET_PROJECT_TASKS),
        GET(ApiUrls.GET_PROJECT_TASKS + `&status=complete`),
      ]);

      if (resUsers.status === 200 && resTasks.status === 200) {
        const combinedTasks = [...resTasks.tasks, ...completeTasks.tasks];
        setAllUsers(resUsers.users);
        setAllTasks(combinedTasks);
        setFilteredTasks(combinedTasks);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const getAssigneeName = (assigneeId) => {
    const user = allUsers.find((user) => user._id === assigneeId);
    if (user) {
      const fullName = `${user.first_name} ${user.last_name}`.trim();
      return fullName.length > 0 ? fullName : "Unknown Assignee";
    } else {
      return "Unknown Assignee";
    }
  };

  const filterTasksByName = () => {
    const filtered = allTasks.flatMap((taskData) =>
      (taskData.tasklists || []).flatMap((tasklist) =>
        (tasklist.tasks || []).filter((task) =>
          getAssigneeName(task.assignee?._id)
            .toLowerCase()
            .includes(filterText.toLowerCase())
        )
      )
    );
    console.log(filtered);
    setFilteredTasks(filtered);
    setFilterApplied(true);
  };

  const clearFilter = () => {
    setFilteredTasks(allTasks);
    setFilterText("");
    setFilterApplied(false);
  };

  const taskDataToDisplay = filteredTasks.flatMap((taskData) =>
    (taskData.tasklists || []).flatMap((tasklist) =>
      (tasklist.tasks || [])
        .filter(
          (task) =>
            task.actual_hours &&
            Array.isArray(task.actual_hours) &&
            task.actual_hours.some((hour) => hour.isRunning)
        )
        .map((task) => ({
          key: task._id,
          taskTitle: task.title,
          clientName: task.project?.client?.first_name || "Unknown Client",
          projectName: taskData.projectName,
          taskLabel: task.label,
          labelColor: task.label_color,
          assigneeName: getAssigneeName(task.assignee?._id),
          assigneeUsername: task.assignee?.username || "Unknown User",
          followers: task.followers || [],
          taskEndDate: task.due_date
            ? moment(task.due_date).format("YYYY-MM-DD")
            : "N/A",
        }))
    )
  );

  const columns = [
    {
      title: "Task",
      dataIndex: "taskTitle",
      key: "taskTitle",
    },
    {
      title: "Client",
      dataIndex: "clientName",
      key: "clientName",
    },
    {
      title: "Project",
      dataIndex: "projectName",
      key: "projectName",
    },
    {
      title: "Task Label",
      dataIndex: "taskLabel",
      key: "taskLabel",
      render: (text, record) => (
        <span
          style={{
            background: record.labelColor,
            color: "#fff",
            padding: "4px 8px",
            borderRadius: "4px",
            minWidth: 80,
            display: "inline-block",
            textAlign: "center",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Assigned to",
      dataIndex: "assigneeName",
      key: "assigneeName",
    },
    {
      title: "Due Date",
      dataIndex: "taskEndDate",
      key: "taskEndDate",
    },
    {
      title: "Timer",
      key: "timer",
      render: (text, record) => (
        <TimerButton
          taskId={record.key}
          assignee={record.assigneeUsername}
          followers={record.followers}
          loginUser={record.assigneeUsername}
        />
      ),
    },
  ];

  return (
    <div>
      <h1
        className="ml-2 my-4"
        style={{
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "25px",
          lineHeight: "31px",
          color: "#2B7AE4",
        }}
      >
        Live Tracking
      </h1>

      <Input
        placeholder="Filter by assignee name"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        style={{ marginBottom: "20px", width: "300px" }}
      />
      <Button
        type="primary"
        onClick={filterTasksByName}
        style={{ marginBottom: "20px", marginLeft: "10px" }}
      >
        Filter Tasks
      </Button>

      {filterApplied && (
        <Button
          type="primary"
          onClick={clearFilter}
          style={{ marginBottom: "20px", marginLeft: "10px" }}
        >
          Clear Filter
        </Button>
      )}

      {loading ? (
        <Spin size="large" />
      ) : (
        <div className="customTable1">
          <Table
            columns={columns}
            dataSource={taskDataToDisplay}
            loading={loading}
            pagination={false}
            rowKey={(record) => record.key}
            locale={{ emptyText: <Empty description="No tasks available" /> }}
          />
        </div>
      )}
    </div>
  );
};

export default LiveTracking;
