/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { Button, Avatar, Tooltip } from "@material-ui/core";
import {
  Comment,
  Mentions,
  Upload,
  Popover,
  Form,
  Input,
  message,
  Empty,
} from "antd";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { TiAttachment } from "react-icons/ti";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { POST, GET, AXIOS_POST, DELETE, PATCH } from "utils/Functions";
import moment from "moment";
import ApiUrls from "utils/ApiUrls";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import { baseeURL } from "utils/Config";
import io from "socket.io-client";
import { RiDeleteBin5Line } from "react-icons/ri";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { AppBar } from "@material-ui/core";

import "./projectMessages.scss";

let socket;

export default function ProjectMessages(props) {
  console.log(props);
  const [showMessageDetails, setShowMessageDetails] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [currentRoomDetails, setCurrentRoomDetails] = useState({});
  // message room listing
  const MessageListing = () => {
    const [form] = Form.useForm();
    const [newDiscussion, setNewDiscussion] = useState({
      project_id: props.location.state._id,
    });
    const [discussionListing, setDiscussionListing] = useState([]);

    const [refresh, setRefresh] = useState(false);
    // get room listing
    useEffect(() => {
      getDiscussionListing();
    }, [refresh]);
    const getDiscussionListing = async () => {
      let resp = await GET(
        ApiUrls.GET_DISCUSSION_COLLECTION + props.location.state._id
      );
      console.log(resp);
      if (resp.status == "200") {
        setDiscussionListing(resp.discussion_collections);
      } else {
        message.error(resp.message);
      }
    };
    // create new room
    const handleSubmit = async () => {
      let resp = await POST(ApiUrls.ADD_DISCUSSION_COLLECTION, newDiscussion);
      console.log(resp);
      form.resetFields();
      if (resp.status == "200") {
        message.success(resp.message);
        setRefresh(!refresh);
      } else {
        message.error(resp.message);
      }
    };
    const content = (
      <div>
        <Form
          form={form}
          preserve={false}
          requiredMark={false}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="discussion"
            label="Discussion Name"
            rules={[
              {
                required: true,
                message: "Please Input Discussion name!",
              },
            ]}
          >
            <Input
              bordered={false}
              className="radiusBorderInput"
              onChange={(e) => {
                setNewDiscussion({ ...newDiscussion, title: e.target.value });
              }}
            />
          </Form.Item>
          <Form.Item name="Description" label="Description">
            <Input
              bordered={false}
              className="radiusBorderInput"
              onChange={(e) => {
                setNewDiscussion({
                  ...newDiscussion,
                  description: e.target.value,
                });
              }}
            />
          </Form.Item>

          <div
            className="mt-2"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              endIcon={<AddCircleOutlineOutlinedIcon />}
              variant="contained"
              type="submit"
              color="primary"
            >
              Create
            </Button>
          </div>
        </Form>
      </div>
    );
    return (
      <div className="messageListing">
        <div className="messageListing__body">
          <div className="container">
            <div className="float-right">
              <Popover
                content={content}
                title="New Discussion"
                trigger="click"
                placement="bottom"
              >
                <Button
                  variant="contained"
                  color="primary"
                  className="rounded-pill"
                >
                  + New Discussion
                </Button>
              </Popover>
            </div>
            <div className="clear-both"></div>
          </div>

          {discussionListing.length == 0 ? (
            <Empty />
          ) : (
            <ul>
              {discussionListing.map((item, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setShowMessageDetails(true);
                    setSelectedID(item._id);
                    setCurrentRoomDetails(item);
                  }}
                >
                  <BiMessageRoundedDetail className="icon" />
                  {item.title}

                  <Tooltip
                    title={
                      item.hide_from_client
                        ? "Unhide from client?"
                        : "Hide from client?"
                    }
                    placement="left"
                  >
                    <Button
                      key="delete"
                      className="rounded-pill hideFromCLient"
                      onClick={async (e) => {
                        e.stopPropagation();

                        let resp = await PATCH(
                          ApiUrls.UPDATE_DISCUSSION_COLLECTION + item._id,
                          {
                            hide_from_client: !item.hide_from_client,
                          }
                        );
                        console.log(resp);
                        if (resp.status == "200") {
                          setRefresh(!refresh);
                        } else {
                          message.error(resp.message);
                        }
                      }}
                    >
                      {item.hide_from_client ? (
                        <EyeInvisibleOutlined />
                      ) : (
                        <EyeOutlined />
                      )}
                    </Button>
                  </Tooltip>

                  <Button
                    key="delete"
                    className="rounded-pill deleteIcon"
                    onClick={async (e) => {
                      e.stopPropagation();

                      let resp = await DELETE(
                        ApiUrls.DELETE_DISCUSSION_COLLECTION + item._id
                      );
                      console.log(resp);
                      if (resp.status == "200") {
                        setRefresh(!refresh);
                      } else {
                        message.error(resp.message);
                      }
                    }}
                  >
                    <RiDeleteBin5Line style={{ color: "#DA0000" }} />
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  };
  // message in one room
  const MessageDetail = () => {
    const [openComment, setOpenComment] = useState(false);
    const [commentVal, setCommentVal] = useState("");
    const [commentMentions, setCommentMentions] = useState([]);
    const [followers, setFollowers] = useState([]);
    const [uploadCommentAttachments, setUploadCommentAttachments] = useState(
      []
    );
    const [commentAttachments, setCommentAttachments] = useState([]);
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null);

    // connect to socket
    useEffect(() => {
      getAllComents();
      getProjectFollowers();
      socket = io(baseeURL);
      console.log(socket);
      socket.emit("connection");
      return () => {
        console.log("unmount");
        socket.close();
      };
    }, [baseeURL, selectedID]);
    // sbscrible socket listener
    useEffect(() => {
      socket.on("message", (newMessage) => {
        // console.log(newMessage, "socket");
        // console.log(messages, "messages");
        setMessages([...messages, newMessage]);
      });
    }, [messages]);
    console.log({ messagesEndRef });
    // scrooll to bottom of messages
    const scrollToBottom = () => {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };
    // get poroject followers
    const getProjectFollowers = async () => {
      let res = await GET(
        ApiUrls.GET_PROJECT_FOLLOWERS + props.location.state._id
      );
      console.log(res);
      if (res.status == "200") {
        setFollowers(res.followers);
      }
    };
    // get all messages
    const getAllComents = async () => {
      let resp = await GET(ApiUrls.GET_ALL_MESSAGES + selectedID);
      console.log(resp);
      if (resp.status == "200") {
        setMessages(resp.comments);
      } else {
        message.error(resp.message);
      }
      scrollToBottom();
    };
    // send message to server
    const sendMessage = async () => {
      try {
        let commentStr = commentVal;
        setCommentVal("");
        setCommentMentions([]);
        setUploadCommentAttachments([]);
        setCommentAttachments([]);
        if (commentVal !== "") {
          let formData = new FormData();
          if (commentAttachments.length > 0) {
            for (let i = 0; i < commentAttachments.length; i++) {
              formData.append(`attachments`, commentAttachments[i]);
            }
          }
          formData.append("message", commentStr);
          formData.append("sending_time", moment());
          formData.append("sender_id", props.loginUserInfo._id);
          formData.append("tagged", JSON.stringify(commentMentions));
          formData.append("project_id", props.location.state._id);
          formData.append("collection_id", selectedID);
          // for (let value of formData.values()) {
          //   console.log(value, "form data");
          // }

          let res = await AXIOS_POST(ApiUrls.ADD_COMMENTS, formData);
          console.log(res);
          if (res.status == 200) {
            socket.emit("sendMessage", res.data.comment_info);
          } else {
            message.error(res.data.message);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    return (
      <div className="messageDetail">
        <div className="messageDetail__body">
          <AppBar position="static" color="primary" className="appbar">
            <div className="d-flex">
              <Button
                startIcon={<ArrowBackIosIcon style={{ fontSize: 12 }} />}
                style={{ color: "#fff" }}
                onClick={() => {
                  setShowMessageDetails(false);
                  setSelectedID(null);
                }}
              >
                Go Back
              </Button>
              <input
                defaultValue={currentRoomDetails.title}
                className="appbar--title"
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    let resp = await PATCH(
                      ApiUrls.UPDATE_DISCUSSION_COLLECTION + selectedID,
                      {
                        title: e.target.value,
                      }
                    );
                    console.log(resp);
                    if (resp.status !== 200) {
                      message.error(resp.message);
                    }
                  }
                }}
              />
            </div>
            <div className="appbar--discription">
              <input
                defaultValue={currentRoomDetails.description}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    let resp = await PATCH(
                      ApiUrls.UPDATE_DISCUSSION_COLLECTION + selectedID,
                      {
                        description: e.target.value,
                      }
                    );
                    console.log(resp);
                    if (resp.status !== 200) {
                      message.error(resp.message);
                    }
                  }
                }}
              />
            </div>
          </AppBar>
          <div className="chat_body mt-3">
            {messages.map((message, index) => (
              <p
                key={index}
                className={`chat_message ${
                  message.sender._id == props.loginUserInfo._id &&
                  "chat_receiver"
                }`}
              >
                <span className="chat_name">
                  {(message.sender.first_name || " ") +
                    " " +
                    (message.sender.last_name || " ") ||
                    message.sender.username}
                </span>
                {message.message}

                {message.attachments.map((file, index) => (
                  <div key={index}>
                    <a
                      className="chat_file"
                      href={baseeURL + file.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TiAttachment />
                      {file.name}
                    </a>
                  </div>
                ))}
                <span className="chat_timestemp">
                  {moment(message.sending_time).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </span>
              </p>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="chat_footer">
            <Comment
              avatar={<Avatar alt="user" />}
              content={
                openComment ? (
                  <>
                    <div className="customEditor">
                      <Mentions
                        autoFocus={true}
                        value={commentVal}
                        onChange={(value) => {
                          setCommentVal(value);
                        }}
                        onSelect={(user) => {
                          if (!commentMentions.includes(user.value)) {
                            setCommentMentions([
                              ...commentMentions,
                              user.value,
                            ]);
                          }
                        }}
                        rows={4}
                        // onPressEnter={}
                      >
                        {followers.map((item, index) => (
                          <Mentions.Option key={index} value={item.username}>
                            {(item?.first_name || "") +
                              (item?.last_name || "") || item?.username}
                          </Mentions.Option>
                        ))}
                      </Mentions>
                      <div className="editorAttachments">
                        <Upload
                          listType="picture"
                          className="w-100"
                          name="commentAttachments"
                          fileList={uploadCommentAttachments}
                          maxCount={10}
                          multiple={true}
                          // eslint-disable-next-line no-unused-vars
                          customRequest={({ file, onSuccess }) => {
                            setTimeout(() => {
                              onSuccess("ok");
                            }, 0);
                          }}
                          onChange={(info) => {
                            // if (info.file.status !== "uploading") {}
                            console.log(info);
                            if (info.fileList.length > 0) {
                              console.log("if inside  upload");
                              setUploadCommentAttachments(info.fileList);
                              setCommentAttachments(() =>
                                info.fileList.map((file) => file.originFileObj)
                              );
                            } else {
                              setUploadCommentAttachments([]);
                              setCommentAttachments([]);
                            }
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <div className="content">
                              <span className="title">Attachments</span>
                              <span className="detail">
                                (you can click to select attachments)
                              </span>
                            </div>
                            <Button
                              color="primary"
                              variant="outlined"
                              className="rounded-pill"
                            >
                              Upload File
                            </Button>
                          </div>
                        </Upload>
                      </div>
                    </div>
                    <div className="mt-3 ">
                      <Button
                        variant="outlined"
                        className="rounded-pill mr-2"
                        onClick={sendMessage}
                      >
                        Add Comment
                      </Button>
                      <Button
                        variant="outlined"
                        className="rounded-pill"
                        onClick={() => {
                          setCommentVal("");
                          setCommentMentions([]);
                          setUploadCommentAttachments([]);
                          setCommentAttachments([]);
                          setOpenComment(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                ) : (
                  <div
                    onClick={() => {
                      setOpenComment(!openComment);
                    }}
                    className="showComment"
                  >
                    Write a comment...
                  </div>
                )
              }
            />
          </div>
        </div>
      </div>
    );
  };
  // show message room listing or message room messages

  if (showMessageDetails) {
    return <MessageDetail />;
  } else {
    return <MessageListing />;
  }
}
