import { makeStyles, Button } from "@material-ui/core";
import React from "react";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import style from "assets/jss/material-dashboard-react/views/genericStyles";
import ProfileImg from "components/SettingComponents/Profile/ProfileImg";

const useImportStyles = makeStyles(style);
export default function ClientProfileDetails(props) {
  const Importclasses = useImportStyles();
  console.log("profile", props);

  return (
    <div className="newClient__content">
      <ProfileImg
        classes="avatar-uploader"
        defaultImg={props.userInfo.avatar}
        userID={props.userInfo._id}
      />

      <h6 className="newClient__content--heading">COMPANY DETAILS</h6>
      <form className="newClient__content--form">
        <div className="row">
          <label
            style={{ whiteSpace: "nowrap" }}
            className="col-sm-3 col-md-3 col-lg-3 m-0   col-form-label"
          >
            Phone Number:
          </label>
          <div className="col-sm-9 col-md-9 col-lg-9 p-0 ml-2.5">
            <input
              type="text"
              className="form-control-plaintext"
              defaultValue="111-111-1111"
            />
          </div>
        </div>
        <div className="row">
          <label
            style={{ whiteSpace: "nowrap" }}
            className="col-sm-3 col-md-3 col-lg-3  col-form-label"
          >
            Company Website:
          </label>
          <div className="col-sm-9 col-md-9 col-lg-9">
            <input
              type="text"
              readOnly
              className="form-control-plaintext"
              defaultValue="ama.com"
            />
          </div>
        </div>
        <div className="row">
          <label
            style={{ whiteSpace: "nowrap" }}
            className="col-sm-3 col-md-3 col-lg-3  col-form-label"
          >
            Company Address:
          </label>
          <div className="col-sm-9 col-md-9 col-lg-9">
            <input
              type="text"
              readOnly
              className="form-control-plaintext"
              defaultValue="123 Easy Rd. San Diego, CA 11111"
            />
          </div>
        </div>
        <h6
          className="newClient__content--heading"
          style={{ marginTop: "25px" }}
        >
          CONTACT DETAILS
        </h6>
        <div className="newClient__content--form">
          <div className="row">
            <label
              style={{ whiteSpace: "nowrap" }}
              className="col-sm-3 col-md-3 col-lg-3  col-form-label"
            >
              Contact Name:
            </label>
            <div className="col-sm-9 col-md-9 col-lg-9">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                defaultValue="Jeff Smith"
              />
            </div>
          </div>
          <div className="row">
            <label
              style={{ whiteSpace: "nowrap" }}
              className="col-sm-3 col-md-3 col-lg-3  col-form-label"
            >
              Company Position:
            </label>
            <div className="col-sm-9 col-md-9 col-lg-9">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                defaultValue="CEO"
              />
            </div>
          </div>
          <div className="row">
            <label
              style={{ whiteSpace: "nowrap" }}
              className="col-sm-3 col-md-3 col-lg-3  col-form-label"
            >
              Email Address:
            </label>
            <div className="col-sm-9 col-md-9 col-lg-9">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                defaultValue="jeffsmith@ama.com"
              />
            </div>
          </div>
          <div className="row">
            <label
              style={{ whiteSpace: "nowrap" }}
              className="col-sm-3 col-md-3 col-lg-3  col-form-label"
            >
              Phone Number:
            </label>
            <div className="col-sm-9 col-md-9 col-lg-9">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                defaultValue="111-222-1111"
              />
            </div>
          </div>
          <Button
            style={{ wordWrap: "normal", marginTop: "35px" }}
            endIcon={<AddCircleOutlineOutlinedIcon />}
            variant="contained"
            classes={{
              root: Importclasses.themeBlue,
              label: Importclasses.whiteLabelColor,
            }}
          >
            Add Contact
          </Button>
        </div>
      </form>
    </div>
  );
}
