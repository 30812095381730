import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { Button, DatePicker, Pagination, Spin, Empty } from "antd";
import { GET } from "utils/Functions";
import ApiUrls from "utils/ApiUrls";
import moment from "moment";

const { RangePicker } = DatePicker;

const Reports = (props) => {
  const [allUsers, setAllUsers] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayTasks, setDisplayTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    try {
      const [resUsers, resTasks, completeTasks, archiveTasks] =
        await Promise.all([
          GET(ApiUrls.GET_ALL_USERS),
          GET(ApiUrls.GET_PROJECT_TASKS),
          GET(ApiUrls.GET_PROJECT_TASKS + `&status=complete`),
          GET(ApiUrls.GET_PROJECT_TASKS + `&status=archive`),
        ]);

      if (resUsers.status === 200 && resTasks.status === 200) {
        const combinedTasks = [
          ...resTasks.tasks,
          ...completeTasks.tasks,
          ...archiveTasks.tasks,
        ];

        setAllUsers(resUsers.users);
        setAllTasks(combinedTasks);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const getAssigneeName = (assigneeId) => {
    const user = allUsers.find((user) => user._id === assigneeId);
    if (user) {
      const fullName = `${user.first_name} ${user.last_name}`.trim();
      return fullName.length > 0 ? fullName : "Unknown Assignee";
    } else {
      return "Unknown Assignee";
    }
  };

  const handleDownload = () => {
    const data = (filterApplied ? displayTasks : dataSource).map((task) => ({
      ProjectName: task.projectName,
      ClientName: task.clientName,
      AssigneeName: task.assigneeName,
      TaskTitle: task.taskTitle,
      TaskStatus: task.taskStatus,
      TaskEstimatedTime: task.taskEstimatedTime,
      TaskActualTime: task.taskActualTime,
    }));

    // Create worksheet for the data
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Create heading array with one row
    const heading = `Report from ${
      filterStartDate ? `${filterStartDate} to ${filterEndDate}` : "All Time"
    }`;
    const headingArray = [[heading]];

    const combinedData = headingArray
      .concat(new Array(1).fill([]))
      .concat(XLSX.utils.sheet_to_json(worksheet, { header: 1 }));

    const combinedSheet = XLSX.utils.aoa_to_sheet(combinedData);

    const colCount = Object.keys(worksheet).reduce(
      (max, key) => Math.max(max, XLSX.utils.decode_col(key) + 1),
      0
    );
    combinedSheet["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: colCount - 1 } },
    ];

    const headingCell = combinedSheet["A1"];
    headingCell.s = {
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
    };

    combinedSheet["!cols"] = new Array(colCount).fill({ width: 20 });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, combinedSheet, "Report");

    const workbookBinary = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "binary",
    });

    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    };

    const blob = new Blob([s2ab(workbookBinary)], {
      type: "application/octet-stream",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "tasks.xlsx";
    link.click();
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const clearFilter = () => {
    setFilterApplied(false);
  };

  const processTasks = (task) => {
    const userHoursMap = task.actual_hours.reduce((acc, hours) => {
      const userId = hours.userId
        ? getAssigneeName(hours.userId)
        : hours.userName ?? getAssigneeName(task.assignee?._id);
      const taskDate = hours.startTime;
      const elapsedTime = hours.elapsedTime;

      if (userId) {
        if (!acc[userId]) {
          acc[userId] = { hoursData: [], startTimesWithElapsedTime: [] };
        }
        acc[userId].hoursData.push(hours);
        acc[userId].startTimesWithElapsedTime.push({
          startTime: taskDate.slice(0, 10),
          elapsedTime: elapsedTime,
        });
      }
      return acc;
    }, {});

    const times = Object.entries(userHoursMap).map(([userId, userData]) => {
      const { hoursData, startTimesWithElapsedTime } = userData;

      const totalElapsedTime = hoursData.reduce(
        (acc, hours) => {
          const [hoursPart, minutesPart, secondsPart] =
            hours.elapsedTime.split(":");
          acc.hours += parseInt(hoursPart, 10);
          acc.minutes += parseInt(minutesPart, 10);
          acc.seconds += parseInt(secondsPart, 10);
          return acc;
        },
        { hours: 0, minutes: 0, seconds: 0 }
      );

      totalElapsedTime.minutes += Math.floor(totalElapsedTime.seconds / 60);
      totalElapsedTime.hours += Math.floor(totalElapsedTime.minutes / 60);
      totalElapsedTime.minutes = totalElapsedTime.minutes % 60;
      totalElapsedTime.seconds = totalElapsedTime.seconds % 60;

      const newTask = {
        ...task,
        assigneeName: userId,
        actual_hours: hoursData,
        taskActualTime: `${totalElapsedTime.hours}:${String(
          totalElapsedTime.minutes
        ).padStart(2, "0")}`,
        taskStartTimes: startTimesWithElapsedTime,
      };

      return newTask;
    });

    return [...times];
  };

  let idCounter = 0;

  const dataSource = allTasks.flatMap((taskData) =>
    taskData.tasklists.flatMap((tasklist) => {
      const tempArr = [];
      tasklist.tasks.forEach((task) => {
        const processedTasks = task.actual_hours ? processTasks(task) : [];

        if (processedTasks.length > 0) {
          const currentArray = processedTasks.map((time) => {
            const isDuplicate = processedTasks.some(
              (processedTask) =>
                processedTask.assigneeName !== time.assigneeName
            );

            return {
              id: idCounter++,
              key: task._id,
              projectName: taskData.projectName,
              clientName: task.project?.client?.first_name || "Unknown Client",
              assigneeName: time.assigneeName,
              taskTitle: task.title,
              taskStatus: task.isArchive ? "archive" : task.status,
              taskEstimatedTime: task.estimatedTime || "N/A",
              taskActualTime: time.taskActualTime || "N/A",
              date: time.taskStartTimes,
              isDuplicate: isDuplicate,
            };
          });
          tempArr.push(...currentArray);
        } else {
          const currentObj = {
            key: task._id,
            id: idCounter++,
            projectName: taskData.projectName,
            clientName: task.project?.client?.first_name || "Unknown Client",
            taskTitle: task.title,
            assigneeName: getAssigneeName(task.assignee?._id),
            taskStatus: task.isArchive ? "archive" : task.status,
            taskEstimatedTime: task.estimatedTime || "N/A",
            taskActualTime:
              processedTasks.length > 0
                ? processedTasks[0].taskActualTime
                : "N/A",
            date: "N/A",
            isDuplicate: false,
          };
          tempArr.push(currentObj);
        }
      });

      return tempArr;
    })
  );

  // console.log("datasource", dataSource);

  const filterTasksByDate = () => {
    if (filterStartDate && filterEndDate) {
      const start = new Date(filterStartDate);
      const end = new Date(filterEndDate);

      const filteredData = dataSource
        .map((item) => {
          if (!Array.isArray(item.date) || item.date.length === 0) return null;

          const filteredDates = item.date.filter((dateEntry) => {
            if (!dateEntry.startTime) return false;

            const taskDate = new Date(dateEntry.startTime);
            return taskDate >= start && taskDate <= end;
          });

          if (filteredDates.length > 0) {
            // Calculate total elapsed time
            const totalElapsedTime = filteredDates.reduce(
              (acc, dateEntry) => {
                const [hours, minutes, seconds] = dateEntry.elapsedTime
                  .split(":")
                  .map(Number);
                acc.hours += hours;
                acc.minutes += minutes;
                acc.seconds += seconds;
                return acc;
              },
              { hours: 0, minutes: 0, seconds: 0 }
            );

            // Convert accumulated time to hours and minutes
            totalElapsedTime.minutes += Math.floor(
              totalElapsedTime.seconds / 60
            );
            totalElapsedTime.hours += Math.floor(totalElapsedTime.minutes / 60);
            totalElapsedTime.minutes = totalElapsedTime.minutes % 60;
            totalElapsedTime.seconds = totalElapsedTime.seconds % 60;

            const taskActualHours = `${totalElapsedTime.hours}:${String(
              totalElapsedTime.minutes
            ).padStart(2, "0")}`;

            return {
              ...item,
              date: filteredDates,
              taskActualTime: taskActualHours,
            };
          } else {
            return null;
          }
        })
        .filter((item) => item !== null);

      console.log("Filtered Data: ", filteredData);
      setDisplayTasks(filteredData);
      setFilterApplied(true);
    }
  };

  // const filterTasksByDate = () => {
  //   if (filterStartDate && filterEndDate) {
  //     const start = new Date(filterStartDate);
  //     const end = new Date(filterEndDate);

  //     const filteredData = dataSource
  //       .map((item) => {
  //         if (!Array.isArray(item.date) || item.date.length === 0) return null;

  //         const filteredDates = item.date.filter((dateEntry) => {
  //           if (!dateEntry.startTime) return false;

  //           const taskDate = new Date(dateEntry.startTime);
  //           return taskDate >= start && taskDate <= end;
  //         });

  //         if (filteredDates.length > 0) {
  //           return { ...item, date: filteredDates };
  //         } else {
  //           return null;
  //         }
  //       })
  //       .filter((item) => item !== null);

  //     console.log(filteredData);
  //     setDisplayTasks(filteredData);
  //     setFilterApplied(true);
  //   }
  // };

  // console.log("-----", displayTasks);

  const calculateTotalElapsedTime = (tasks) => {
    const total = tasks.reduce(
      (acc, task) => {
        if (task.taskActualTime) {
          const [hours, minutes] = task.taskActualTime.split(":").map(Number);
          acc.hours += hours;
          acc.minutes += minutes;
        }
        return acc;
      },
      { hours: 0, minutes: 0 }
    );

    total.hours += Math.floor(total.minutes / 60);
    total.minutes %= 60;

    return `${total.hours}:${total.minutes.toString().padStart(2, "0")}`;
  };

  return (
    <>
      <div className="flex justify-between">
        <h1
          className="ml-4 mb-4"
          style={{
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "25px",
            lineHeight: "31px",
            color: "#2B7AE4",
          }}
        >
          Reports
        </h1>
        <Button
          type="primary"
          onClick={handleDownload}
          style={{ background: "green", borderRadius: "5px" }}
        >
          Download Report
        </Button>
      </div>

      {filterApplied && (
        <div className="float-right bg-blue-light p-3 rounded-lg shadow-lg">
          <h4 className="text-black font-bold">
            Total Elapsed Time {calculateTotalElapsedTime(displayTasks)}
          </h4>
        </div>
      )}

      {loading ? (
        <div className="example">
          <Spin />
        </div>
      ) : (
        <>
          <div style={{ marginBottom: 16 }}>
            <RangePicker
              format="YYYY-MM-DD"
              value={
                filterStartDate && filterEndDate
                  ? [moment(filterStartDate), moment(filterEndDate)]
                  : []
              }
              onChange={(dates) => {
                if (dates) {
                  setFilterStartDate(dates[0]?.format("YYYY-MM-DD") || null);
                  setFilterEndDate(dates[1]?.format("YYYY-MM-DD") || null);
                } else {
                  setFilterStartDate(null);
                  setFilterEndDate(null);
                }
              }}
            />
            <Button
              onClick={filterTasksByDate}
              type="primary"
              style={{ marginLeft: 8 }}
            >
              Filter
            </Button>

            {filterApplied && (
              <Button
                type="primary"
                onClick={clearFilter}
                style={{ marginLeft: "10px" }}
              >
                Clear Filter
              </Button>
            )}
          </div>

          <div className="relative sm:overflow-x-auto sm:rounded-lg mt-4">
            <table className="w-full text-sm text-left rtl:text-right ">
              <thead className="text-xs  uppercase bg-blue-lightest">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    ID
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Project Name
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Client Name
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Task Title
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Assigned To
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Estimated Time
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Actual Time
                  </th>
                </tr>
              </thead>
              <tbody>
                {(filterApplied ? displayTasks : dataSource).length > 0 ? (
                  (filterApplied ? displayTasks : dataSource)
                    .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                    .map((task) => (
                      <tr
                        key={task.id}
                        className={`border-b ${
                          task.isDuplicate ? "bg-gray-gray-1" : "bg-white"
                        }`}
                      >
                        <td className="px-4 py-3">{task.id}</td>
                        <td className="px-4 py-3">{task.projectName}</td>
                        <td className="px-4 py-3">{task.clientName}</td>
                        <td className="px-4 py-3 w-40">{task.taskTitle}</td>
                        <td className="px-4 py-3">{task.assigneeName}</td>
                        <td className="px-4 py-3">{task.taskStatus}</td>
                        <td className="px-4 py-3">{task.taskEstimatedTime}</td>
                        <td className="px-4 py-3">{task.taskActualTime}</td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="8">
                      <Empty className="w-full" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              onChange={(page) => setCurrentPage(page)}
              total={(filterApplied ? displayTasks : dataSource).length}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Reports;
