import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  SwipeableDrawer,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Icon,
} from "@material-ui/core";
import axios from "axios";
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import ProduxoLogo from "assets/img/logo.png";
import Timer from "components/Timer/Timer";
import CreateButton from "components/CreateButton/CreateButton";
import Logout from "components/Logout/Logout";
import { connect } from "react-redux";
import NewTimer from "components/Timer/NewTimer";
import TimerButton from "components/TrackTime/TimerButton";
import { baseeURL } from "utils/Config";

const useStyles = makeStyles(styles);

function Sidebar(props) {
  const classes = useStyles();
  const [taskId, setTaskId] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const storedUserInfo = localStorage.getItem("user_info");
    if (storedUserInfo) {
      const parsedUserInfo = JSON.parse(storedUserInfo);
      setUserInfo(parsedUserInfo);
      console.log(parsedUserInfo);
    }
  }, []);

  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  const { color, image, routes } = props;

  const fetchActiveTimer = async () => {
    try {
      const resp = await axios.get(
        `${baseeURL}/api/tasks/${userInfo?._id}/has-active-timer`
      );
      console.log("API response:", resp.data);
      if (
        resp.status === 200 &&
        resp.data.hasActiveTimer &&
        resp.data.taskData.length > 0
      ) {
        // console.log("Active task data:", resp.data.taskData[0]);
        setTaskId(resp.data.taskData[0]._id);
      } else {
        console.log("No active timer found");
        setTaskId(null);
      }
    } catch (error) {
      console.error("Error fetching active timer:", error);
    }
  };

  useEffect(() => {
    fetchActiveTimer();
    const handleTimerAction = () => {
      fetchActiveTimer();
    };
    window.addEventListener("timerAction", handleTimerAction);
    return () => {
      window.removeEventListener("timerAction", handleTimerAction);
    };
  }, [userInfo?.username]);

  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;

        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.layout + prop.path),
        });

        const blueFontClasses = classNames({
          [" " + classes.blueFont]: activeRoute(prop.layout + prop.path),
        });
        return (
          <NavLink
            to={prop.layout + prop.path}
            className={activePro + classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, blueFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive,
                  })}
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon
                  className={classNames(classes.itemIcon, blueFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive,
                  })}
                />
              )}
              <ListItemText
                primary={props.rtlActive ? prop.rtlName : prop.name}
                className={classNames(classes.itemText, blueFontClasses, {
                  [classes.itemTextRTL]: props.rtlActive,
                })}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );

  var brand = (
    <div className={classes.logo}>
      <div className={classes.logoImage}>
        <img src={ProduxoLogo} width="150px" alt="logo" />
      </div>
    </div>
  );

  return (
    <div>
      <Hidden mdUp implementation="css">
        <SwipeableDrawer
          variant="temporary"
          anchor={"left"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, classes.drawerPaperRTL),
          }}
          onClose={props.handleDrawerToggle}
          onOpen={props.handleOpenDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {brand}
          <div
            className="mb-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {props.userType === 0 ? <CreateButton /> : null}
          </div>
          <Divider />
          <div className={classes.sidebarWrapper}>
            <div onClick={props.handleDrawerToggle}>{links}</div>
            {props.userType === 0 ? <Timer /> : null}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
              }}
              onClick={fetchActiveTimer}
            >
              {taskId && (
                <TimerButton
                  taskId={taskId}
                  assignee={userInfo._id}
                  followers={[]}
                  loginUser={userInfo._id}
                />
              )}
            </div>

            <Logout />
          </div>
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={"left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          {brand}
          <div
            className="mb-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {props.userType === 0 ? <CreateButton /> : null}
          </div>
          <Divider />
          <div className={classes.sidebarWrapper}>
            {links}
            {props.userType === 0 ? <NewTimer /> : null}
            {taskId && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={fetchActiveTimer}
              >
                <div
                  style={{
                    // backgroundColor: "#ffcccb",
                    padding: "10px",
                    borderRadius: "8px",
                    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    textAlign: "center",
                    width: "100%",
                    maxWidth: "400px",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  <h3
                    style={{
                      margin: "0 0 10px 0",
                      color: "gray",
                      fontWeight: "bold",
                    }}
                  >
                    Timer Running
                  </h3>
                  <TimerButton
                    taskId={taskId}
                    assignee={userInfo._id}
                    followers={[]}
                    loginUser={userInfo?._id}
                  />
                </div>
              </div>
            )}

            <Logout />
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    userType: state.Login.user_info.role.title,
  };
};

export default connect(mapStatetoProps)(Sidebar);

Sidebar.propTypes = {
  userType: PropTypes.number,
  handleDrawerToggle: PropTypes.func,
  handleOpenDrawerToggle: PropTypes.func,
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
