import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import "./LandingPage.css";
import img1 from "../../assets/img/1.png";
import img2 from "../../assets/img/2.png";
import img3 from "../../assets/img/3.png";

export default function WelcomeNavbar(props) {
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={require("../../assets/img/logo1.png")}
              alt="Logo"
              style={{
                width: "auto",
                height: "70px",
              }}
            />
          </div>
          <Button
            style={{
              background: "white",
              color: "#2b7ae4",
            }}
            variant="contained"
            onClick={() => {
              props.history.push("/user/login");
            }}
          >
            Log In
          </Button>
        </Toolbar>
      </AppBar>

      <div>
        {/* Hero Section */}
        <div class="main">
          <div class="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div class="max-w-3xl mx-auto text-center">
              <h1 class="hero-heading">
                <span class="block pt-10">
                  Empower teams and individuals to efficiently
                </span>
                <span class="block">create, collaborate and innovate</span>
              </h1>
              <p class="hero-text">
                Our comprehensive platform leverages cutting-edge technology to
                streamline workflows and empower data-driven decision-making.
              </p>
              <div class="hero-button">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    props.history.push("/user/register");
                  }}
                >
                  Register Now
                </Button>

                <span
                  style={{
                    color: "red",
                    fontSize: "18px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "20px",
                    fontStyle: "italic",
                  }}
                >
                  Free for limited time!
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Hero Feature Section */}
        <div className="hero-main">
          <div className="containerr">
            <div className="hero-text">
              <h1>Project Management</h1>
              <p>
                Handle your projects by assigning appropriate team members,
                setting timelines, and monitoring working hours dedicated to
                them.
              </p>
            </div>
            <div className="hero-image">
              <img src={img1} alt="" />
            </div>
          </div>
        </div>

        <div className="hero-main">
          <div className="containerr">
            <div className="hero-image">
              <img src={img2} alt="" />
            </div>
            <div className="hero-text">
              <h1>Task Management</h1>
              <p>
                Create a task list for each project, define tasks, assign them
                to the appropriate team members, and set deadlines.
              </p>
            </div>
          </div>
        </div>

        <div className="hero-main">
          <div className="containerr">
            <div className="hero-text">
              <h1>User Management</h1>
              <p>
                Invite Owners, Collaborators, Members, and Clients in your
                workspace to manage them with varying levels of permissions.
              </p>
            </div>
            <div className="hero-image">
              <img src={img3} alt="" />
            </div>
          </div>
        </div>

        {/* CTA */}
        <div className="cta">
          <div className="container mx-auto">
            <h2>Ready to get started?</h2>
            <p
              style={{
                color: "red",
                fontStyle: "italic",
              }}
            >
              Free for limited time!
            </p>

            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                props.history.push("/user/register");
              }}
            >
              Register Now
            </Button>
          </div>
        </div>
        {/* Footer */}
        <div className="footer">
          <div className="container mx-auto">
            <p>&copy; 2023 - Produxo All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
