import React, { useState, useEffect } from "react";
import HourglassBottomIcon from "@material-ui/icons/HourglassEmpty";
import Button from "@material-ui/core/Button";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import { message } from "antd";
import axios from "axios";
import { baseeURL } from "utils/Config";
import moment from "moment";

const TimerButton = ({ taskId, assignee, loginUser, followers }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [elapsedSeconds, setElapsedSeconds] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const followerId = followers.map((item) => item.follower._id);

  loginUser === assignee || followerId.includes(loginUser);

  // console.log(followerId);

  useEffect(() => {
    const fetchTimerData = async () => {
      try {
        const resp = await axios.get(`${baseeURL}/api/tasks/${taskId}/timer`);

        if (resp.status === 200) {
          const timerData = resp.data.data;

          if (timerData && timerData.isRunning) {
            const integerElapsedSeconds = Math.floor(timerData.elapsedSeconds);

            if (timerData.isPaused) {
              setElapsedSeconds(integerElapsedSeconds);
            } else {
              const startTime = new Date(timerData.startTime);
              // const now = new Date();
              const now = moment().utcOffset("+05:00");
              const elapsedMilliseconds = now - startTime;
              const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
              setElapsedSeconds(elapsedSeconds);
            }

            setIsRunning(true);
            setIsPaused(timerData.isPaused);
          } else {
            setIsRunning(false);
            setIsPaused(false);
            setElapsedSeconds(0);
          }
        }
      } catch (error) {
        console.error("Failed to fetch timer data", error);
      }
    };

    fetchTimerData();

    let interval;
    if (isRunning && !isPaused) {
      interval = setInterval(() => {
        setElapsedSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        fetchTimerData();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearInterval(interval);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isRunning, isPaused, taskId]);

  const handleStart = async (event) => {
    if (isRunning) {
      setAnchorEl(event.currentTarget);
    } else {
      try {
        // Fetch to check if the user has an active timer on another task
        const resp = await axios.get(
          `${baseeURL}/api/tasks/${loginUser}/has-active-timer`
        );

        if (resp.status === 200 && resp.data.hasActiveTimer) {
          message.error("You already have an active timer on another task.");
          return;
        }

        // Check if the login user is the assignee or a follower
        const isAllowed =
          !resp.data.hasActiveTimer &&
          // (loginUser === assignee || followerId.includes(loginUser));
          loginUser === assignee;

        if (isAllowed) {
          const startTime = new Date().toISOString();
          const userId = loginUser;

          const startResp = await axios.patch(
            `${baseeURL}/api/tasks/${taskId}/timer/start`,
            { startTime, userId }
          );

          if (startResp.status === 200) {
            setIsRunning(true);
            setIsPaused(false);
            message.success("Timer started successfully.");

            window.dispatchEvent(new Event("timerAction"));
          } else {
            message.error("Failed to start timer.");
          }
        } else {
          message.error("You are not allowed to start a new timer.");
        }
      } catch (error) {
        console.error("Failed to start timer", error);
        message.error("Failed to start timer.");
      }
    }
  };

  const handlePause = async () => {
    if (isPaused) {
      // Resume the timer
      try {
        const resp = await axios.patch(
          `${baseeURL}/api/tasks/${taskId}/timer/resume`
        );
        if (resp.status === 200) {
          setIsPaused(false);
          message.success("Timer resumed successfully.");
        }
      } catch (error) {
        console.error("Failed to resume timer", error);
        message.error("Failed to resume timer.");
      }
    } else {
      // Pause the timer
      try {
        const resp = await axios.patch(
          `${baseeURL}/api/tasks/${taskId}/timer/pause`,
          { elapsedSeconds }
        );
        if (resp.status === 200) {
          setIsPaused(true);
          message.success("Timer paused successfully.");
        }
      } catch (error) {
        console.error("Failed to pause timer", error);
        message.error("Failed to pause timer.");
      }
    }
    setAnchorEl(null);
  };

  const handleStop = async () => {
    try {
      const resp = await axios.patch(
        `${baseeURL}/api/tasks/${taskId}/timer/stop`,
        { elapsedSeconds }
      );
      if (resp.status === 200) {
        setIsRunning(false);
        setIsPaused(false);
        setElapsedSeconds(0);
        message.success("Timer stopped successfully.");
      }
      const event = new Event("timerAction");
      window.dispatchEvent(event);
    } catch (error) {
      console.error("Failed to stop timer", error);
      message.error("Failed to stop timer.");
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        endIcon={<HourglassBottomIcon />}
        variant="contained"
        color="primary"
        onClick={(event) => handleStart(event)}
      >
        {isRunning ? formatTime(elapsedSeconds) : "Start Timer"}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box>
          <IconButton onClick={handlePause} color="primary">
            {isPaused ? <PlayArrowIcon /> : <PauseIcon />}
          </IconButton>
          <IconButton onClick={handleStop} color="secondary">
            <StopIcon />
          </IconButton>
        </Box>
      </Popover>
    </>
  );
};

const formatTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export default TimerButton;
