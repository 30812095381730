/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Button as MuiButton, IconButton } from "@material-ui/core";
// @material-ui/icons
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";

import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";

// core components
import { Table, Row } from "react-bootstrap";
import { Select, Input as AntInput, Form, Menu, Spin, Dropdown } from "antd";

import { motion, AnimatePresence } from "framer-motion";

import { Popover, message, DatePicker } from "antd";
import { POST, GET, PATCH } from "utils/Functions";
import { Empty } from "antd";
import ApiUrls from "utils/ApiUrls";

import "./projectsTable.scss";
import { connect } from "react-redux";
const { Option } = Select;

const projectFilterOptions = ["active", "complete", "archive"];

const key = "updatable";

function ProjectsTable(props) {
  const [state, setState] = useState({ visible: false });
  const [newProject, setNewProject] = useState({
    client_id: null,
    new_client: null,
    projectName: "",
    type: "",
  });
  const [allClients, setAllClients] = useState([]);
  const [allProjects, setAllProjects] = useState(null);
  const [openNewClientFields, setOpenNewClientFields] = React.useState(false);
  const [refresh, setRefresh] = useState(false);

  const [projectStatusFilter, setProjectStatusFilter] = useState(
    projectFilterOptions[0]
  );
  const [clientFilter, setClientFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [openSelect, setOpenSelect] = useState(false);

  useEffect(() => {
    getallclient();
    getAllProjects();
  }, [refresh]);

  useEffect(() => {
    if (allProjects !== null) {
      filterProject();
    }
  }, [projectStatusFilter, clientFilter]);

  const getallclient = async () => {
    let res = await GET(ApiUrls.GET_ALL_CLIENTS);
    if (res.status == "200") {
      setAllClients(res.clients);
    }
  };
  const getAllProjects = async () => {
    let res = await GET(
      ApiUrls.GET_ALL_PROJECTS +
        `name=${clientFilter}&status=${projectStatusFilter}`
    );
    setLoading(false);
    if (res.status == "200") {
      setPaginate({
        ...paginate,
        pageSize: res.per_page,
        totalRecord: res.total,
        currentPage: res.current_page,
      });
      setAllProjects(res.projects);
    }
  };

  const SettingMenu = (id) => (
    <Menu>
      <Menu.Item onClick={() => deleteProject(id)}>{"Delete"}</Menu.Item>
    </Menu>
  );

  const deleteProject = async (projectID) => {
    message.loading({ content: "Action in progress..", key });

    let res = await PATCH(ApiUrls.DELETE_PROJECT + projectID);
    if (res.status == "200") {
      message.success({
        content: res.message,
        key,
        duration: 2.5,
      });
      setRefresh(!refresh);
    } else {
      message.error(res.message);
    }
  };

  /* Start  Pagination data  */
  const [paginate, setPaginate] = useState({
    pageSize: 0,
    currentPage: null,
    totalRecord: null,
  });

  const handlePageChange = async (page) => {
    let res = await GET(
      ApiUrls.GET_ALL_PROJECTS +
        `name=${clientFilter}&status=${projectStatusFilter}&page=${page}`
    );
    if (res.status == "200") {
      setPaginate({
        ...paginate,
        pageSize: res.per_page,
        totalRecord: res.total,
        currentPage: res.current_page,
      });
      setAllProjects(res.projects);
    }
  };

  /* End  Pagination data  */

  const filterProject = async () => {
    setLoading(true);
    let res = await GET(
      ApiUrls.GET_ALL_PROJECTS +
        `name=${clientFilter}&status=${projectStatusFilter}`
    );
    setLoading(false);

    if (res.status === 200) {
      setPaginate({
        ...paginate,
        pageSize: res.per_page,
        totalRecord: res.total,
        currentPage: res.current_page,
      });
      setAllProjects(res.projects);
    } else {
      message.error(res.message);
    }
  };
  const [form] = Form.useForm();
  const handleSubmit = async () => {
    hide();
    let resp = await POST(ApiUrls.ADD_PROJECT, newProject);
    form.resetFields();
    if (resp.status == "200") {
      message.success("Document Added Successfully.");
      setRefresh(!refresh);
    } else {
      message.error(resp.message);
    }
  };
  const hide = () => {
    setState({
      visible: false,
    });
    setNewProject({});
    setOpenNewClientFields(false);
  };

  const handleVisibleChange = (visible) => {
    form.resetFields();
    setState({ visible });
    setNewProject({});
    setOpenNewClientFields(false);
  };

  const popoverForm = () => (
    <>
      <Form
        form={form}
        preserve={false}
        requiredMark={false}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="type"
          label="Document Type"
          rules={[
            {
              required: true,
              message: "Please select Document Type!",
            },
          ]}
        >
          <Select
            value={newProject.type}
            className="w-100 radiusBorderInput"
            bordered={false}
            listItemHeight={10}
            listHeight={250}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(value) => {
              setNewProject({
                ...newProject,
                type: value,
              });
            }}
          >
            <Option className="antSelect" value="type1">
              Contract
            </Option>
            <Option className="antSelect" value="type2">
              Proposal
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="client"
          label="Client"
          rules={[
            {
              required: true,
              message: "Please Select Client!",
            },
          ]}
        >
          <Select
            value={newProject.client}
            className="w-100 radiusBorderInput"
            bordered={false}
            listItemHeight={10}
            listHeight={250}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(value) => {
              setNewProject({
                ...newProject,
                client_id: value,
              });
            }}
            onSelect={(value) => {
              if (value == "new_client") {
                setOpenSelect(false);
                setNewProject({
                  ...newProject,
                  client: null,
                });

                setTimeout(() => {
                  setOpenNewClientFields(true);
                }, 500);
              }
              setOpenNewClientFields(false);
            }}
          >
            <Option className="antSelect" value="new_client">
              + New Client
            </Option>
            {allClients.map((item, index) => (
              <Option
                className="antSelect"
                style={{ textTransform: "capitalize" }}
                key={index}
                value={item._id}
              >
                {item.name}
                <p style={{ textTransform: "lowercase", fontSize: 10 }}>
                  &nbsp;&nbsp;{item.email}
                </p>
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div>
          <AnimatePresence>
            {openNewClientFields ? (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Form.Item
                  name="newClient"
                  label="Client Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input New Client Name!",
                    },
                  ]}
                >
                  <AntInput
                    value={newProject.new_client?.name}
                    className="radiusBorderInput"
                    onChange={(event) => {
                      event.persist();
                      setNewProject(() => {
                        return {
                          ...newProject,
                          new_client: {
                            ...newProject.new_client,
                            name: event.target.value,
                          },
                        };
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Client Email"
                  name="clientEmail"
                  className="w-100 "
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "The input is not valid Email!",
                    },
                  ]}
                >
                  <AntInput
                    value={newProject.new_client?.email}
                    className="radiusBorderInput"
                    onChange={(event) => {
                      event.persist();

                      setNewProject(() => {
                        return {
                          ...newProject,
                          new_client: {
                            ...newProject.new_client,
                            email: event.target.value,
                          },
                        };
                      });
                    }}
                  />
                </Form.Item>
              </motion.div>
            ) : null}
          </AnimatePresence>
        </div>
        <Form.Item
          label="Project Name"
          name="projectName"
          className="w-100"
          rules={[
            {
              required: true,
              message: "Please input Project Name!",
            },
          ]}
        >
          <Select
            value={newProject.projectName}
            className="w-100 radiusBorderInput"
            bordered={false}
            listItemHeight={10}
            listHeight={250}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(value) => {
              setNewProject({
                ...newProject,
                projectName: value,
              });
            }}
          >
            {allProjects.map((project) => (
              <Option
                className="antSelect"
                key={project.projectName}
                value={project.projectName}
              >
                {project.projectName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* <AntInput
              value={newProject.projectName}
              className="w-100 radiusBorderInput"
              bordered={false}
              onChange={(event) => {
                setNewProject({
                  ...newProject,
                  projectName: event.target.value,
                });
              }}
              placeholder="Enter new project name"
            /> */}

        {/* <Form.Item
          label="Project Name"
          name="projectName"
          className="w-100 "
          rules={[
            {
              required: true,
              message: "Please input Project Name!",
            },
          ]}
        >
          <AntInput
            value={newProject.projectName}
            className="radiusBorderInput"
            onChange={(event) => {
              event.persist();
              setNewProject(() => {
                return {
                  ...newProject,
                  projectName: event.target.value,
                };
              });
            }}
          />
        </Form.Item> */}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <MuiButton
            endIcon={<AddCircleOutlineOutlinedIcon />}
            variant="contained"
            type="submit"
            color="primary"
          >
            Create
          </MuiButton>
        </div>
      </Form>
    </>
  );
  const TableRow = ({ item }) => (
    <tr>
      <td style={{ textTransform: "capitalize" }}>{item.projectName}</td>
      <td>
        {(item.client.first_name || "") + " " + (item.client.last_name || "")}
      </td>

      <td>{item.status}</td>
      <td style={{ minWidth: 150 }}></td>

      <Dropdown
        trigger={["click"]}
        overlay={SettingMenu(item._id, item.status, item.isArchived)}
        placement="bottomCenter"
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        <IconButton
          style={{
            padding: "3px",
            alignItems: "right",
          }}
        >
          <SettingsOutlinedIcon style={{ fill: "#DADADA" }} />
        </IconButton>
      </Dropdown>
    </tr>
  );

  return (
    <div className="projectTable__content w-100">
      <Row className="projectTable__content--buttons w-100 justify-content-between">
        <div>
          <Select
            allowClear={true}
            placeholder="All Documents"
            className="mr-2 filterButton"
            bordered={false}
            listItemHeight={10}
            listHeight={250}
            onChange={(value) => {
              if (value !== undefined) setProjectStatusFilter(value);
              else setProjectStatusFilter("");
            }}
            defaultValue="All Documents"
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {projectFilterOptions.map((val, index) => (
              <Option
                className="antSelect"
                style={{ textTransform: "capitalize" }}
                key={index}
                value={val}
              >
                {val}
              </Option>
            ))}
          </Select>
          {props.userType === "owner" || props.userType === "collaborator" ? (
            <Select
              placeholder="All Statuses"
              className="filterButton"
              bordered={false}
              listItemHeight={10}
              listHeight={250}
              defaultValue="All Statuses"
            ></Select>
          ) : null}
        </div>

        {props.userType === "owner" || props.userType === "collaborator" ? (
          <Popover
            content={popoverForm}
            title="New Document"
            trigger="click"
            visible={state.visible}
            onVisibleChange={handleVisibleChange}
            overlayStyle={{ height: "100vh", overflow: "auto" }}
          >
            <MuiButton
              endIcon={<AddCircleOutlineOutlinedIcon />}
              variant="contained"
              className="float-right"
              color="primary"
            >
              New Document
            </MuiButton>
          </Popover>
        ) : null}
      </Row>

      <div className="customTable">
        <Table responsive borderless>
          <thead>
            <tr className="tableHeading">
              <th scope="col">Type </th>
              <th scope="col">Client </th>
              <th scope="col">Projects</th>
              <th scope="col">Action </th>
            </tr>
          </thead>
          <tbody>
            {allProjects !== null && allProjects.length > 0 ? (
              allProjects.map((client, index) => (
                <TableRow key={index} item={client} index={index} />
              ))
            ) : loading ? (
              <tr>
                <td colSpan="11">
                  <Spin />
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan="11">
                  <Empty className="w-100 " />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
const mapStatetoProps = (state) => {
  return {
    myId: state.Login.user_info._id,
    userType: state.Login.user_info?.role?.title,
  };
};
export default connect(mapStatetoProps)(ProjectsTable);
